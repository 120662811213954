import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import ReactTooltip from "react-tooltip";
import copy from "copy-to-clipboard";
import config from "../config/config";
import tokenABI from "../ABI/tokenABI.json";
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, getFormatMulticall } from "../actions/comman";
import { buynewLevel, allLevel, transactionHistory , getcorevotinglist, get_communityvotinglist} from "../actions/users";
import { createvoting } from "../actions/users";
import { getprovider } from "../actions/walletconnect.js";
import { toastAlert } from "../helper/toastAlert.js";
import Web3 from "web3";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import bnb_usdABI from "../ABI/bnb_usd.json";
import ReactLoading from "react-loading";
import { InlineShareButtons } from "sharethis-reactjs";
import $ from "jquery";
import { referalTreeLevelNext } from "../pages/tree";
import DataTable from 'react-data-table-component';
import moment from "moment";
import Countdown from "react-countdown";

// Trade History Table
const data = [
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "500",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "10000",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "100000",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "378520",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "685123",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "247963",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "7412",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "789456",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999",status: "buylevel"},
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999",status: "buylevel"},
  ];
  


var zeroAddr = "0x0000000000000000000000000000000000000000";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var initialData = {
  "name": "",
  "symbol": "",
  "address": "",
  "logoURI": "",
  "amount": "",
  "showamount": "",
  "value": ""
}

var priceinterval;

export default function Dashboard(props) {
  let [loader, setloader] = useState();
  const [fromValue, setfromValue] = useState(initialData);
  const [toValue, settoValue] = useState(initialData);
  const [current, setcurrent] = useState("");
  const [swapcurrent, setswapcurrent] = useState("");
  const [frombalance, setfrombalance] = useState({ "balance": "Loading", "balanceOf": 0 });
  const [tobalance, settobalance] = useState({ "balance": 0, "balanceOf": 0 });

  const [showapprove, setshowapprove] = useState(false);
  const [approvebtn, setapprovebtn] = useState(false);
  const [swapbtn, setswapbtn] = useState(false);

  const [insufficienterror, setinsufficienterror] = useState(false);
  const [Insufficienttoken, setInsufficienttoken] = useState("");

  const [checkallowance, setcheckallowance] = useState(false);
  const [showswap, setshowswap] = useState(false);

  const [slippageValue, setslippageValue] = useState(0.1);
  const [transdeadline, settransdeadline] = useState(5);
  const [pairisExits, setpairisExits] = useState("");

  const [minimumReceived, setminimumReceived] = useState(0);
  const [priceImpact, setpriceImpact] = useState(0);
  const [liquidityFee, setliquidityFee] = useState(0);
  const [currentRouter, setcurrentRouter] = useState("");
  const [swaploading, setswaploading] = useState(false);

  const [tokenList, settokenList] = useState([]);
  const [swapList, setswapList] = useState([]);
  const [tokenprice, settokenprice] = useState(0);
  const [loadhistory, setloadhistory] = useState(false);

  const [showChart, setshowChart] = useState(true);
  const [receivedamt, setreceivedamt] = useState(0);
  const [enterValue, setenterValue] = useState(0);
  const [perRate, setperRate] = useState(0);
  const [swapService, setswapService] = useState(0);
  const [fromOriginal, setfromOriginal] = useState(0);
  const [priceDetail, setpriceDetail] = useState({ usdPrice: 0, bnbPrice: 0 });

  const [PairData, setPairData] = useState({});
  const [isLoadPair, setisLoadPair] = useState(false);
  const [authValue, setauthValue] = useState("");
  const [isChange, setisChange] = useState(false);
  const [adminAddr, setadminAddr] = useState("");
  const [votinglist, setvotinglist] = useState([]);
  const [communitylist, setcommunitylist] = useState([]);
  const [filter, setfilter] = useState("vote");

  useEffect(() => {
    getvotinglist();
    getcommunityvotinglist();
  }, []);

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span></span>
    } else {
      return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
  };

  async function getvotinglist() {
    var record = await getcorevotinglist();
    if (record && record.list ) {
      setvotinglist(record.list)
    }
  }
  async function getcommunityvotinglist() {
    var record = await get_communityvotinglist();
    console.log(record,"------")
    if (record && record.list ) {
      setcommunitylist(record.list)
    }
  }

  async function filterchange(chk) {
    console.log(chk,"===============================")
    setfilter(chk)
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <ReactTooltip />
      <div className="inner_wrapper">
        <div className="inner_pageheader">
          <div className="container">
            <div className="primary_box dash_box make_proposal_panel mb-5">
                  <div className="inner_banner main-banner exch-bnr">
                    <div className="inner_banner_content">
                      <div className="inner_title_top">
                        <h1>Voting</h1>
                      </div>
                      <p className="w-100">Have your say in the future of the social community.</p>
                      <div className="voting_banner_btn">
                        <button className="primary_btn w_sm_100_btn ml-0">
                          <Link to="/makeproposal">Make a Proposal</Link>
                        </button>
                      </div>
                    </div>
                  </div>
            </div>
          </div>

          <div className="inner_content_wrapper">
            <div className="container">
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="inner_title mb-3">Proposals</h2>
                  {/*  <select className="d_d_none custom_select proposal_filter_select">
                      <option>Vote Now</option>
                      <option>Coming Soon</option>
                      <option>Closed</option>
                    </select> */}
                  </div>

                  <div className="swap_pro_order_history">
                    <div className="voting_top_flex">
                      <div className="voting_top_flex_btns d_m_none">
                        <button className={filter == "vote" ? "active" : ""} onClick={() => filterchange("vote")}>Vote now</button>
                        <button className={filter == "upcoming" ? "active" : ""} onClick={() => filterchange("upcoming")}>Coming soon</button>
                        <button className={filter == "closed" ? "active" : ""} onClick={() => filterchange("closed")}>Closed</button>
                      </div>
                      <ul class="nav nav-tabs nav_primary" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <a class="nav-link active" id="your_order-tab" data-toggle="tab" href="#your_order" role="tab" aria-controls="your_order" aria-selected="true">Core</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-link" id="all_order-tab" data-toggle="tab" href="#all_order" role="tab" aria-controls="all_order" aria-selected="false">Community</a>
                        </li>
                      </ul>                      
                    </div>
                    <div className="primary_box dash_box make_proposal_panel">
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="your_order" role="tabpanel" aria-labelledby="your_order-tab">
                          <div className="row">
                          {votinglist && votinglist.length > 0  && votinglist.map((item) => {
                              var a = moment(item.startdate);
                              var b = moment(item.enddate);
                              var c = moment();
                              var startdiff = a.diff(c)
                              var enddiff = b.diff(c)
                              var interdiff = b.diff(a)
                              if ((parseInt(startdiff) > 0 && filter == "upcoming") ||
                                (parseInt(enddiff) > 0 && filter == "vote" && parseInt(startdiff) <= 0) ||
                                (parseInt(enddiff) < 0 && filter == "closed")) {
                                return (
                                  <div className="col-md-6">
                                    <Link to={"/voting-details/" + item._id} className="voting_proposal_div">
                                      <div className="voting_proposal_div_left">
                                        <div className="voting_details_head_min">
                                          <h2>{item.title}</h2>
                                          <div className="d-flex justify-content-between align-items-center">
                                            {filter == "closed" ?
                                              <p className="mb-3 voting_status status_closed">Closed</p>
                                              :
                                              filter == "upcoming" ?
                                                <p className="mb-3 voting_status status_coming_soon">Coming Soon</p>
                                                :
                                                <p className="mb-3 voting_status status_open"><Countdown
                                                  date={b}
                                                  autoStart={true}
                                                  onStart={() => Date.now()}
                                                  renderer={renderer}
                                                ></Countdown></p>
                                            }
                                            <p className="d_d_none voting_mobile_details">Details</p>
                                          </div>
                                        </div>
                                        <div class="progress mt-3 mb-2">
                                          <div class="progress-bar w-50" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          {item.choice && (item.choice).map((choice, i) => {
                                            return (
                                              <p className={i == 0 ? "agree_text" : "disagree_text"}>{choice.option}</p>
                                            )
                                          })}
                                        </div>
                                      </div>
                                      <div className="voting_proposal_div_right d_m_none">
                                        <img src={require("../assets/images/arrow_more_details.png")} alt="Icon" />
                                      </div>
                                    </Link>
                                    </div>
                                )
                              }
                            }
                            )
                          }
                          {votinglist && votinglist.length<=0 &&
                          <p className="mb-3 voting_status status_closed">No Records Found</p>
                          }
                          {/*votinglist && votinglist.length>0 &&
                            <div className="d_d_none text-center mt-3">
                              <p className="pink_light_txt">More<img src={require("../assets/images/dropdown_arrow_white.png")} alt="More Icon" className="ml-1" /></p>
                            </div>
                          */}
                          </div>
                        </div>

                        <div class="tab-pane fade" id="all_order" role="tabpanel" aria-labelledby="all_order-tab">
                          <div className="row">
                         {communitylist && communitylist.length > 0 && communitylist.map((item) => {
                              let choice_arr = [];
                              (item.choice).forEach((choose)=>{
                                let val = (item.votes).filter(x => x.choice==choose.option).length
                                var choosed = {
                                  name: choose.option,
                                  count: val
                                }
                                choice_arr.push(choosed)
                              })
                              var a = moment(item.startdate);
                              var b = moment(item.enddate);
                              var c = moment();
                              var startdiff = a.diff(c)
                              var enddiff = b.diff(c)
                              var interdiff = b.diff(a)
                              if ((parseInt(startdiff) > 0 && filter == "upcoming") ||
                                (parseInt(enddiff) > 0 && filter == "vote" && parseInt(startdiff) <= 0) ||
                                (parseInt(enddiff) < 0 && filter == "closed")) {
                                return (
                                  <div className="col-md-6">
                                    <Link to={"/voting-details/" + item._id} className="voting_proposal_div">
                                      <div className="voting_proposal_div_left">
                                        <div className="voting_details_head_min">
                                          <h2>{item.title}</h2>
                                          <div className="d-flex justify-content-between align-items-center">
                                            {filter == "closed" ?
                                              <p className="mb-3 voting_status status_closed">Closed</p>
                                              :
                                              filter == "upcoming" ?
                                                <p className="mb-3 voting_status status_coming_soon">Coming Soon</p>
                                                :
                                                <p className="mb-3 voting_status status_open"><Countdown
                                                  date={b}
                                                  autoStart={true}
                                                  onStart={() => Date.now()}
                                                  renderer={renderer}
                                                ></Countdown></p>
                                            }
                                            <p className="d_d_none voting_mobile_details">Details</p>
                                          </div>
                                        </div>
                                       {filter != "upcoming" && 
                                          <div class="progress mt-3 mb-2">
                                            <div class={"progress-bar w-"+choice_arr[0].count} role="progressbar" aria-valuenow={choice_arr[0].count} aria-valuemin="0" aria-valuemax="100"></div>
                                          </div>
                                        }
                                        <div className="d-flex justify-content-between">
                                          {filter != "upcoming" && item.choice && (item.choice).map((choice, i) => {
                                            return (
                                              <p className={i == 0 ? "agree_text" : "disagree_text"}>{choice.option}</p>
                                            )
                                          })}
                                        </div>
                                      </div>
                                      <div className="voting_proposal_div_right d_m_none">
                                        <img src={require("../assets/images/arrow_more_details.png")} alt="Icon" />
                                      </div>
                                    </Link>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                        {communitylist && communitylist.length <=0 &&
                          <p className="mb-3 voting_status status_closed">No Records Found</p>
                        }
                        {/*communitylist && communitylist.length > 0 &&
                          <div className="d_d_none text-center mt-3">
                            <p className="pink_light_txt">More<img src={require("../assets/images/dropdown_arrow_white.png")} alt="More Icon" className="ml-1" /></p>
                          </div>
                        */}
                      </div>
                    </div>
                  </div>
                  <div className="got_suggest mt-5">
                    <h2 className="inner_title mb-2">Got a suggestion?</h2>
                    <p>Propose an idea to help improve the Cryptopump community. Not all successful votes will be implemented but suggestions with a lot of community support may be made into Core proposals.</p>
                  </div>
                  <div className="text-center">
                    <button className="primary_btn ml-0 w_sm_100_btn">
                      <Link to="/makeproposal"> Make a Proposal</Link>
                    </button>
                  </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader_section_">
          <ReactLoading type={"bars"} color={"#ffffff"} className="loading" />
        </div>
      )}
      <Footer />
    </div>
  );
}
