import Web3 from 'web3';
import { Multicall } from "ethereum-multicall";
import $ from "jquery";

import crytopumpABI from '../ABI/cryptopump.json'
import { getFormatMulticall1 } from '../actions/comman';
import config from '../config/config';

var zeroAddr = "0x0000000000000000000000000000000000000000";

export async function referalTreeLevelNext(web3, firstLevel, treecount, setfirstTree, setsecondTree, setthirdTree,setfirstTreelevel,setsecondTreelevel,setthirdTreelevel) {
    console.log(nexttree, 'nexttreenexttree-nexttreenexttree')
    treecount++;
    var web3 = web3;
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: false });
    var TreeContract = [];
    console.log(firstLevel, 'firstLevelfirstLevel-firstLevelfirstLevel')
    for (var i = 0; i < firstLevel.length; i++) {

        TreeContract.push({
            reference: "tree-" + i,
            contractAddress: config.contractaddress,
            abi: crytopumpABI,
            calls: [
                {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [firstLevel[i]],
                }
            ]
        })

    }

    const getTree = await multicall.call(TreeContract);
    var level=[];
    var treeList = [];
    var userids = [];
    var nexttree = [];
    var userlevel = [];

    for (var j = 0; j < firstLevel.length; j++) {
        var refList = await getFormatMulticall1(getTree, "tree-" + j, 0);
        if (refList.length == 0) {
            refList = [zeroAddr, zeroAddr]
        } else if (refList.length == 1) {
            refList.push(zeroAddr);
        }
        // console.log(refList,"refList")
        for (var k = 0; k < refList.length; k++) {
            var Contract = new web3.eth.Contract(crytopumpABI, config.contractaddress);
            var usersinfo = await Contract.methods.users(refList[k]).call();
            console.log(usersinfo[1],"userinfo---------------12")
            userids.push(parseInt(usersinfo[1]));
            nexttree.push(refList[k]);
            level.push(usersinfo[3]);
        }
        // console.log(level,"userids----123");
        if (refList.length == 0) {
            treeList.push([0, 0])
            userlevel.push([{id:0,level:0},{id:0,level:0}])
        } else if (refList.length == 1) {
            treeList.push([userids[0], 0])
            userlevel.push([{id:userids[0],level:level[0]},{id:0,level:0}])
            // userlevel.push(level)
        } else if (refList.length == 2) {
            treeList.push([userids[0], userids[1]])
            // userlevel.push(level)
            userlevel.push([{id:userids[0],level:level[0]},{id:userids[1],level:level[1]}])
        }
        userids = [];
        level = [];
    }

    if (treecount == 1) {
        setfirstTree([])
        setfirstTree(treeList);
        setfirstTreelevel(userlevel)
    }
    if (treecount == 2) {
        setsecondTree([]);
        setsecondTree(treeList);
        setsecondTreelevel(userlevel)
    }
    if (treecount == 3) {
        setthirdTree([])
        setthirdTree(treeList)
        setthirdTreelevel(userlevel)
    }
    if (treecount < 4) {
        referalTreeLevelNext(web3, nexttree, treecount, setfirstTree, setsecondTree, setthirdTree,setfirstTreelevel,setsecondTreelevel,setthirdTreelevel);
    }
// console.log(userlevel,'userlevel')
}