import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer.js';
import Navbar from '../components/Navbar.js';
import CountUp from 'react-countup';
import ScrollAnimation from 'react-animate-on-scroll';
import Wallet_Popup from '../components/Wallet_Popup';
import { Link } from 'react-router-dom';
import Web3 from 'web3';
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, getFormatMulticall,getFormatMulticallNumber } from '../actions/comman';
import { topusers, weeklyuser, getTopCoinList, getToken } from '../actions/users';
import { getprovider } from '../actions/walletconnect';
import bnb_usdABI from '../ABI/bnb_usd.json';
import config from '../config/config';
import crytopumpABI from '../ABI/cryptopump.json';
import TokenABI from '../ABI/tokenABI.json';
import moment from 'moment';

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
let initial ={
  ciruculationSupply: 0,
  maxSupply: 0,
  Marketcap: 0,
  price: 0,
  totalSupply:0,
}
export default function Home(props) {
  let [PopupStatus, setPopupStatus] = useState();
  let [usdPrice, setusdPrice] = useState();
  let [totalbnb, settotalbnb] = useState();
  let [totaluser, settotaluser] = useState();
  let [topuser, settopuser] = useState();
  let [newcommers,setnewcommers] = useState();
  let [topCoinList,setTopCoinList] = useState([]);
  let [tokendata,settokendata] = useState(initial)
  var navicate = useNavigate()

  var CryptopumpContract = [
    {
      reference: "dashboard",
      contractAddress: config.contractaddress,
      abi: crytopumpABI,
      calls: [
        {
          reference: "totalbnb",
          methodName: "totalAmount",
        },
        {
          reference: "totaluser",
          methodName: "currentId"
        }
      ]
    }
  ];
  var TokenContract = [
    {
      reference: "token",
      contractAddress: config.tokenaddress,
      abi: TokenABI,
      calls: [
        {
          reference: "totalSupply",
          methodName: "totalSupply",
        },
        {
          reference: "balanceOf",
          methodName: "balanceOf",
          methodParameters: ["0x000000000000000000000000000000000000dEaD"],
        }
      ]
    }
  ];

  useEffect(() => {
    init();
    getTopCoin()
    gettokendetails()
  }, [])

  const getTopCoin = async () =>{
    var response = await getTopCoinList()
    setTopCoinList(response.result)
    console.log(response,'res========================-----------');
  }
  const gettokendetails =async()=>{
    let token = await getToken();
    settokendata({...tokendata,...token.result})
  }

  const init = (async () => {
    try{
    let { provider, address } = await getprovider()
    console.log(address, config.contractaddress, config.provider, 'proverssssss_home');
    let web3 = new Web3(config.provider);
    console.log(web3,"web3");
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: false });

    const dashboardresults = await multicall.call(CryptopumpContract);
    console.log(dashboardresults,"dashboardresults");
    let web3js = new Web3(config.liverpcUrls);
    let USD_pricecontract = new web3js.eth.Contract(bnb_usdABI, config.bnb_usd);
    let usdpriceval = await USD_pricecontract.methods.latestAnswer().call();
    let convert = usdpriceval / 1e8;
    console.log('good',usdpriceval);
    setusdPrice(convert);
    const totalAmount = await getFormatMulticall1(dashboardresults, "dashboard", 0);
    const totaluser = await getFormatMulticall(dashboardresults, "dashboard", 1);
    let users = parseInt(totaluser.hex);
    let amounts = parseInt(totalAmount[0].hex) / 1e18;
    settotaluser(users);
    settotalbnb(amounts);
    let { user, status } = await topusers();

    let {week, status1} = await weeklyuser();
    setnewcommers(week.data)
    console.log(week,'newcommers')
    console.log(user, 'topusrsssss')
    settopuser(user.price);
    console.log(usdpriceval, "pricessssss", parseInt(totaluser.hex), 'totaluser', parseInt(totalAmount[0].hex) / 1e18, 'totalamount')
    }catch(err){
      console.log(err,"error")
    }
  })

  const wallet = (() => {
    window.$("#connect_wallet_modal").show();
    // setPopupStatus(true);
  })

  const navications =(()=>{
    navicate("/register")
  })
  const whitpaper =(()=>{
    window.open('https://docdro.id/EFso6qL','_blank')
    // navicate("","_blank")
  })

  const Buytoken =(()=>{
    window.open('https://pancakeswap.finance/swap?outputCurrency=0x5c741F6CBa99738a371bc76033d73402Cd686902','_blank')
    // navicate("","_blank")
  })
  var partners_slider_settings = {
    centerMode: true,
    centerPadding: '1px',
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        }
      }
    ]
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className='page_header'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <ScrollAnimation animateIn='slideInUp'><h1>#1 Smart Contract For VIP signal Cryptocurrency trading</h1></ScrollAnimation>
              <ScrollAnimation animateIn='slideInUp' delay={1000}><h5>The most innovative 100% Decentralized Matrix Platform</h5></ScrollAnimation>
              {!localStorage.getItem("account") ? <ScrollAnimation animateIn='slideInUp' delay={1200}><button className='primary_btn btnHomeJoin' data-toggle="modal" data-target="#connect_wallet_modal">Connect Wallet</button></ScrollAnimation> :
                <ScrollAnimation animateIn='slideInUp' delay={1200}><button onClick={navications} className='primary_btn btnHomeJoin'>Join Us</button></ScrollAnimation>}
                 {/* <ScrollAnimation animateIn='slideInUp' delay={1200}><button className='primary_btn' data-toggle="modal" data-target="#register_modal">Join Us</button></ScrollAnimation> */}
            </div>
          </div>
        </div>
      </div>
      <div className='main_wrapper'>

        {/* Community Section */}
        <section className='community_section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-6'>
                <ScrollAnimation animateIn='zoomIn'>
                  <h3 className='main_title_small'>01  //  International Community</h3>
                  <h2 className='main_title'>Global VIP trading signal business</h2>
                  <p className='inner_para'>Only with 0.038 BNB and you will get a VIP trading signal, and it will be conveyed via the dashboard, there is no compulsion for you to start trading, Cryptopump trading signals are only limited to what Token or Coin information is currently trending. And Cryptopump is marketed openly and decentralized using a matrix system.</p>
                </ScrollAnimation>
              </div>
              <div className='col-md-6 col-lg-6'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='primary_box community_box'>
                    <div className='d-flex align-items-center'>
                      <img src={require("../assets/images/icon_01.png")} className='img-fluid mr-3' alt='Icon' />
                      <h4>Risk Free</h4>
                    </div>
                    <p className='inner_para'>Cryptopump developers deploy self-executing smart contracts on the Binance smart Chain (BSC). There is no doubt because all use a decentralized Blockchain system.</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col-md-6 col-lg-4'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='primary_box community_box'>
                    <div className='d-flex align-items-center'>
                      <img src={require("../assets/images/icon_02.png")} className='img-fluid mr-3' alt='Icon' />
                      <h4>Transactions</h4>
                    </div>
                    <p className='inner_para'>The profit routes from other members directly into your personal wallet. There is no hoarding in the system, the income belongs only to you.</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col-md-6 col-lg-4'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='primary_box community_box'>
                    <div className='d-flex align-items-center'>
                      <img src={require("../assets/images/icon_03.png")} className='img-fluid mr-3' alt='Icon' />
                      <h4>Decentralization</h4>
                    </div>
                    <p className='inner_para'>There are no managers or administrators, there are only the creators who are equal participants in the project, like everyone else.</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col-md-6 col-lg-4'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='primary_box community_box'>
                    <div className='d-flex align-items-center'>
                      <img src={require("../assets/images/icon_04.png")} className='img-fluid mr-3' alt='Icon' />
                      <h4>100% Online</h4>
                    </div>
                    <p className='inner_para'>All funds are transferred between members, there are no hidden fees. The contract balance is always zero.</p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* Count Section */}
        <section className='counter_section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-10 m-auto'>
                <ScrollAnimation animateIn='zoomIn'><p>* Cryptopump provides a signal at an unspecified time, always follows its growth and development, and Cryptopump is not your financial advisor, but only provides information on tokens/coins that are currently trending. Keep DYOR (Do Your Own Research) because you manage your own funds, Cryptopump is not responsible for your financial decisions.</p></ScrollAnimation>
              </div>
              <div className='col-md-12 col-lg-12'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='primary_box counter_box'>
                    <div className='counter_panel'>
                      <div>
                        <h3><CountUp end={totaluser} duration={3} /></h3>
                        <h6>All Participants</h6>
                      </div>
                      {/* <div>
                        <h3><CountUp end={newcommers} duration={3} /></h3>
                        <h6>24 Hours New Comers</h6>
                      </div> */}
                      <div>
                        <h3><CountUp end={totalbnb} decimals={5} duration={3} /></h3>
                        <h6>Total Earned BNB</h6>
                      </div>
                      <div>
                        <h3><CountUp end={(totalbnb * usdPrice)} decimals={5} duration={3} /></h3>
                        <h6>Total Earned USD</h6>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* Partners Section */}
        <section className='partners_section'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-md-6 col-lg-6'>
                <ScrollAnimation animateIn='zoomIn'>
                  <h3 className='main_title_small'>02  //  Verified</h3>
                  <h2 className='main_title'>Partner’s Result</h2>
                  <p className='inner_para'>All data is stored on the blockchain in the public domain and can be verified!</p>
                  <p className='inner_para'>Contract address: <a className='d-block' href={config.bscscan} target="_blank">{config.contractaddress}</a></p>
                </ScrollAnimation>
              </div>
              <div className='col-md-6 col-lg-6 text-center'>
                <ScrollAnimation animateIn='zoomIn'>
                  <img src={require("../assets/images/partner_img.png")} className='img-fluid' alt='Icon' />
                </ScrollAnimation>
              </div>
            </div>

            <div className='partners_slider mt-5'>
               <Slider {...partners_slider_settings}>
              {topuser &&
                topuser.length > 0 &&
                topuser.map((item, i) => {



                  var price = (item && item.price) ? parseFloat(item.price).toFixed(5) : 0;
                  var usdVal =(price > 0) ? parseFloat(price * usdPrice).toFixed(5) : 0;
                  return (
                    
                    <div className='itemCard'>
                        <div className='primary_box partner_box'>
                          <div className='partner_box_left'>
                           {item.touserId&&<h2>ID {item.touserId}</h2>}
                            <div className='d-flex align-items-center justify-content-center'>
                              <img src={require("../assets/images/id_icon.png")} className='img-fluid mr-3' alt='Icon' />
                              {item.userdata&&item.userdata.referId&&<h5>{item.userdata.referId}</h5>}
                            </div>
                          </div>
                          <div className='partner_box_right'>
                            <div>
                              <p>Balance in USD</p>
                              {usdVal&&<h3>$ <span>{usdVal}</span></h3>}
                            </div>
                            <div>
                              <p>BNB balance</p>
                              {price&&<h3><span>{price}</span> BNB</h3>}
                            </div>
                            <div>
                              <p>Registration Date</p>
                              {item.userdata&&item.userdata.createdAt&&<h3>{moment(item.userdata.createdAt).format("DD-MM-YYYY")}</h3>}
                            </div>
                          </div>
                        </div>
                    </div>
                   
                  );
                })}
  </Slider>
            </div>
          </div>
        </section>

        {/* Commision Section */}
        {/* <section className='commission_section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-6'>
                <ScrollAnimation animateIn='zoomIn'>
                  <h3 className='main_title_small'>03  //  Commission Payout System</h3>
                  <h2 className='main_title'>Partner Income Explained</h2>
                  <p className='inner_para'>Active Users will receive daily announcement from admin panel on top dashboard, which include history, countdown of level after expiring and to initiate users to buy package. Inactive users will not receive this signal trading.</p>
                  <div className='commision_table_div'>
                    <table className='table commision_table'>
                      <thead>
                        <tr>
                          <th>Signal Trading</th>
                          <th>Expiration Period</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Package 1</td>
                          <td>10 days (Trial)</td>
                        </tr>
                        <tr>
                          <td>Package 2</td>
                          <td>10 days (Trial)</td>
                        </tr>
                        <tr>
                          <td>Package 3</td>
                          <td>30 days</td>
                        </tr>
                        <tr>
                          <td>Package 4</td>
                          <td>30 days</td>
                        </tr>
                        <tr>
                          <td>Package 5</td>
                          <td>60 days</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col-md-12 col-lg-6'>
                <ScrollAnimation animateIn='zoomIn'><img src={require("../assets/images/commision_img.png")} className='img-fluid d-block mx-auto' alt='Icon' /></ScrollAnimation>
                <ScrollAnimation animateIn='zoomIn'><div className='text-center mt-5'><button onClick={navications} className='primary_btn btnHomeJoin' data-toggle="modal" data-target="#exampleModal">Join Now</button></div></ScrollAnimation>
              </div>
            </div>
          </div>
        </section> */}

        {/* TopCoin Section */}
        <section className='commission_section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-6'>
                <ScrollAnimation animateIn='zoomIn'>
                  <h3 className='main_title_small'>03  //  Top Coins</h3>
                  <h2 className='main_title'>The top 5 votes</h2>
                  <p className='inner_para'>The top 5 votes will be chosen randomly by the system and will be traded on weekends Sunday at 21.00 utc announced via the dashboard for Level 3 and level 5 participants for those who have upgraded level 5 will get a notification 5 minutes faster than level 3.</p>
                  <div className='commision_table_div'>
                    <table className='table commision_table'>
                      <thead>
                        <tr>
                        <th>S.No</th>
                        <th>Coin</th>
                          <th>Coin Name</th>
                          <th>Coin Pair</th>
                          <th>Votes</th>
                        </tr>
                      </thead>
                      {
                       topCoinList&&topCoinList.length > 0 ?  topCoinList.map((item,ide)=>{
                            return(
                      <tbody>
                      
                        <tr>
                        <td>{ide+1}</td>
                        <td> <img style={{width:'54px'}} src={`${config.backurl}/images/coins/${item.logo}`} className={'coinImg'} /></td>
                                <td>{item.coin_name}</td>
                                <td>{item.coin_symbol}</td>
                                <td>{item.votes}</td>
                              </tr>
                         
                      </tbody>
                       )
                      }) : (<tbody style={{color:'#d6dadf'}}>
                        <tr>
                          <td colSpan={5}>
                            <p className="text-center">No records to display</p>
                          </td>
                        </tr>
                      </tbody>)
                    }
                    </table>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col-md-12 col-lg-6 text-center'>
                {/* <ScrollAnimation animateIn='zoomIn'><img src={require("../assets/images/commision_img.png")} className='img-fluid d-block mx-auto' alt='Icon' /></ScrollAnimation> */}
                <ScrollAnimation animateIn='zoomIn'>
                  <div className='coin_info_wrap'>
                    <div>
                      <label>Circulating Supply</label>
                      <h4>{tokendata.ciruculationSupply.toLocaleString()}</h4>
                    </div>
                    <div>
                      <label>Total Supply</label>
                      <h4>{tokendata.totalSupply.toLocaleString()}</h4>
                    </div>
                    <div>
                      <label>Max Supply</label>
                      <h4>{tokendata.maxSupply.toLocaleString()}</h4>
                    </div>
                    <div>
                      <label>Market Cap</label>
                      <h4>$ {tokendata.Marketcap.toLocaleString()}</h4>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='zoomIn'>
                  <div className='text-center mt-5'>
                    <button onClick={navications} className='primary_btn btnHomeJoin join_now_btn' data-toggle="modal" data-target="#exampleModal">Join Now</button>
                  </div>
                  <button onClick={whitpaper} target="_blank" className='primary_btn btnHomeJoin'>Whitepaper</button>
                  <button onClick={Buytoken} target="_blank" className='primary_btn btnHomeJoin ml-3'>Buy Token</button>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* faq Section */}
        <section className='faq_section'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-md-6 col-lg-6'>
                <ScrollAnimation animateIn='zoomIn'>
                  <h3 className='main_title_small'>04  //  Do you have any problem?</h3>
                  <h2 className='main_title'>Question and Answers?</h2>
                  <p className='inner_para'>Learn everything you need to know about our tournaments! For more info, please reach out to us.</p>
                </ScrollAnimation>
              </div>
              <div className='col-md-6 col-lg-6 text-right'>
                <ScrollAnimation animateIn='zoomIn'>
                  <img src={require("../assets/images/faq_img.png")} className='img-fluid' alt='Icon' />
                </ScrollAnimation>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className="accordion" id="faq_accodian">
                  <ScrollAnimation animateIn='flipInX'>
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                          <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          <span>What network does Crytopump use?</span>
                          </button>
                        </h2>
                      </div>

                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#faq_accodian">
                        <div className="card-body">
                          <p>Cryptopum uses the Binance smart chain network and uses the BNB coin.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn='flipInX'>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                          <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          <span>How does Cryptopump work?</span>
                          </button>
                        </h2>
                      </div>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#faq_accodian">
                        <div className="card-body">
                          <p>The metric system is just a plan that is all in the same portion, i.e. the member does not have an administrator and does not accommodate any funds.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn='flipInX'>
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                          <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          <span>Is it safe to trade using signals from cryptopump?</span>
                          </button>
                        </h2>
                      </div>
                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#faq_accodian">
                        <div className="card-body">
                          <p>All trading carries risks, and we are not your financial advisor, cryptopump only provides information on a VIP basis. and all are grown together with all communities.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>

      {/* Connect Wallet Modal */}
      <Wallet_Popup setPopupStatus={setPopupStatus} PopupStatus={setPopupStatus} />

      {/* Register Modal */}
      <div className="modal fade primary_modal" id="register_modal" tabindex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="registerModalLabel">Register</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='inp_grp'>
                <p>Referral ID</p>
                <input type="text" className="form-control primary_inp" />
              </div>
              <div className='mt-3 text-center'>
                <button type='button' className='primary_btn'>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}