import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Home from './pages/home.js';
import Dashboard from './pages/dashboard';
import Register from './pages/register';
import Login from './pages/login';
import Proposalmake from './pages/make-proposal';
import Createcoin from './pages/create_coin/create-coin';
import Votingdetails from './pages/voting-detail';
import Coinlist from './pages/coin_list/coin_list';
import Voting from './pages/voting';
import { Provider } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/register/:id" element={<Register />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/makeproposal" element={<Proposalmake />}></Route>
          <Route path="/create-coin" element={<Createcoin />}></Route>
          <Route path="/coin-list" element={<Coinlist />}></Route>
          <Route path="/voting" element={<Voting />}></Route>
          <Route path="/voting-details/:id" element={<Votingdetails />}></Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
