import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseurl;

export const allLevel = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/allLevel`,
      data
    });
    console.log(respData,"resssssss");
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (e) {
    console.log(e, "allLevelallLevel");
  }
}

export const getreferral = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-referral`,
            'data': data,
        });
        return {
            loading: false,
            result: respData.data.list
        }
    }
    catch (err) {
        return {
            loading: false,
            error: returnErr(err)
        }
    }
}


export const getToken = async () => {
  try {
      let respData = await axios({
          'method': 'get',
          'url': `${baseUrl}/get-tokendetails`,
      });
      return {
          loading: false,
          result: respData.data.data
      }
  }
  catch (err) {
      return {
          loading: false,
          error: returnErr(err)
      }
  }
}

export const createvoting = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/create_voting`,
            'data': data,
        });
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: returnErr(err)
        }
    }
}


export const register = async (data) => {
  try {
    console.log(baseUrl);
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/register`,
      data: data,
    });
    return {
      message: respData.data.message,
      status: respData.data.status,
      err: respData.data.err,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
}

export const checkregister = async (data) => {
  try {
      let respData = await axios({
          'method': 'get',
          'url': `${baseUrl}/check-user?address=`+data,
      });
      return {
           user: respData.data.user,
           status: respData.data.status
      }
  }
  catch (err) {
      return {
          loading: false,
          error: err
      }
  }
}
export const buynewLevel = async (data) => {
  try {
    console.log(baseUrl);
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/buynewLevel`,
      data: data,
    });
    return {
      message: respData.data.message,
      status: respData.data.status,
      err: respData.data.err,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
}

export const topusers = async (data) => {
  try {
      let respData = await axios({
          'method': 'get',
          'url': `${baseUrl}/top-users`,
      });
      return {
           user: respData.data,
           status: respData.data.status
      }
  }
  catch (err) {
      return {
          loading: false,
          error: err
      }
  }
}

export const weeklyuser = async (data) => {
  try {
      let respData = await axios({
          'method': 'get',
          'url': `${baseUrl}/weeklyuser`,
      });
      return {
           week: respData.data,
           status1: respData.data.status
      }
  }
  catch (err) {
      return {
          loading: false,
          error: err
      }
  }
}


export const headerSearch = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/header-search?search=`+data.search,
        });
        return {
            loading: false,
             records: respData.data.result,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}


  export const getcorevotinglist = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/votinglist_core`,
        });
        return {
            loading: false,
            list: respData.data.list
        }
    }
    catch (err) {
        return {
            loading: false,
            error: returnErr(err)
        }
    }
  }

  export const getdetailvoting = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/votingdetails`,
            'data': data
        });
        return {
            loading: false,
            list: respData.data.list
        }
    }
    catch (err) {
        return {
            loading: false,
            error: returnErr(err)
        }
    }
}

export const voteupdate = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/updatevote`,
            'data': data
        });
        return {
            loading: false,
            list: respData
        }
    }
    catch (err) {
        return {
            loading: false,
            error: returnErr(err)
        }
    }
}

  export const get_communityvotinglist = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/votinglist_community`,
        });
        return {
            loading: false,
            list: respData.data.list
        }
    }
    catch (err) {
        return {
            loading: false,
            error: returnErr(err)
        }
    }
  }

  export const transactionHistory = async (data) => {
    try {
      console.log(baseUrl);
      let respData = await axios({
        method: "post",
        url: `${baseUrl}/transactionHistory`,
        data: data,
      });
      return {
        message: respData.data.message,
        status: respData.data.status,
        err: respData.data.err,
        data: respData.data.data,
      };
    } catch (err) {
      return {
        err: returnErr(err),
      };
    }
  }

  function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
      return err.response.data.errors;
    } else {
      return "";
    }
  }

  export const getvotingPower = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}/get-votingpower`,
            data: data,
        })
        return {
            message: respData.data.data,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}

export const getTopCoinList = async (data) => {
  try {
      let respData = await axios({
          method: 'get',
          url:
              `${baseUrl}/topcoinlist`
      })
      return {
          result: respData.data.result,
      }
  } catch (err) {
    console.log(err,'{}{}{}{');
      return {
          errors: returnErr(err),
      }
  }
}

export const getTradecoin = async (data) => {
  try {
      let respData = await axios({
          method: 'get',
          url:
              `${baseUrl}/tradecoin`
      })
      return {
          result: respData.data.result,
      }
  } catch (err) {
    console.log(err,'{}{}{}{');
      return {
          errors: returnErr(err),
      }
  }
}
