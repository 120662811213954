import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import ReactTooltip from "react-tooltip";
import copy from "copy-to-clipboard";
import config from "../config/config";
import crytopumpABI from "../ABI/cryptopump.json";
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, getFormatMulticall } from "../actions/comman";
import { buynewLevel, allLevel, transactionHistory, getTradecoin } from "../actions/users";
import { getprovider } from "../actions/walletconnect.js";
import { toastAlert } from "../helper/toastAlert.js";
import Web3 from "web3";
import bnb_usdABI from "../ABI/bnb_usd.json";
import ReactLoading from "react-loading";
import { InlineShareButtons } from "sharethis-reactjs";
import $ from "jquery";
import { referalTreeLevelNext } from "../pages/tree";
import DataTable from 'react-data-table-component';
import Moment from 'moment'

// Trade History Table
const data = [
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "500", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "10000", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "100000", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "378520", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "685123", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "247963", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "7412", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "789456", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
];



var zeroAddr = "0x0000000000000000000000000000000000000000";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialclaimValue = {
  'token2': 0,
  'token3': 0,
  'token4': 0,
  'token5': 0,
}
export default function Dashboard(props) {
  let [PopupStatus, setPopupStatus] = useState();
  let [userdetails, setuserdetails] = useState();
  let [level1price, setlevel1price] = useState();
  let [level2price, setlevel2price] = useState();
  let [level3price, setlevel3price] = useState();
  let [level4price, setlevel4price] = useState();
  let [level5price, setlevel5price] = useState();
  let [tokenamount, settokenamount] = useState(initialclaimValue);
  let [claimstatus, setclaimstatus] = useState(0);
  let [rewardstatus, setrewardstatus] = useState(false);
  let [usdPirce, setusdPrice] = useState();
  let [totalbnb, settotalbnb] = useState();
  let [totaluser, settotaluser] = useState();
  let [SearchID, setSearchID] = useState();
  let [validation, setvalidation] = useState(false);
  let [loader, setloader] = useState();
  let [tradetoken, settradetoken] = useState();

  let [copytxt, setcopytxt] = useState(false);
  let [copytxt1, setcopytxt1] = useState(false);
  let [copytxt2, setcopytxt2] = useState(false);

  let [rootId, setrootId] = useState();
  let [firstTree, setfirstTree] = useState([]);
  let [secondTree, setsecondTree] = useState([]);
  let [thirdTree, setthirdTree] = useState([]);
  const [allData, setallData] = useState();
  let [buystatus, setbuystatus] = useState(0);
  let [txhistory, settxhistory] = useState([]);
  let [firstTreelevel, setfirstTreelevel] = useState([]);
  let [secondTreelevel, setsecondTreelevel] = useState([]);
  let [thirdTreelevel, setthirdTreelevel] = useState([]);
  let [Reward, setReward] = useState(0);
  let [totalPoolUsers, settotalPoolUsers] = useState()


  var navigate = useNavigate();
  let address = localStorage.getItem("account");
  console.log(address, config.contractaddress, "arguments");
  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
  }
  const columns = useMemo(
    () => [
      {
        name: 'Date & Time',
        selector: 'createdAt',
        cell: (row) => { return Moment(row.createdAt).format('DD-MM-YYYY hh:mm') },
        sortable: false,
      },
      {
        name: 'Transaction Id',
        selector: 'txnhash',
        sortable: false,
        width: "450px"
      },
      {
        name: 'Level',
        selector: 'level',
        sortable: false,
      },
      {
        name: 'Amount',
        selector: 'price',
        cell: (row) => { return convert(row.price) },
        sortable: false,
      },
      {
        name: 'status',
        selector: 'transactionType',
        sortable: false,
        cell: row => {
          let styleName;
          if (row.toaddress.toLowerCase() == address) {
            styleName = 'btn btn-success btn-sm'
          } else {
            styleName = 'btn btn-danger btn-sm'
          }
          return (
            <Fragment>
              <a href={config.bsctrx + row.txnhash}
                className={styleName}
                style={{ marginRight: '5px' }} target="_blank">
                {row.transactionType}
              </a>
            </Fragment>
          );
        }
      }
    ], []);
  var CryptopumpContract = [
    {
      reference: "dashboard",
      contractAddress: config.contractaddress,
      abi: crytopumpABI,
      calls: [
        {
          reference: "users",
          methodName: "users",
          methodParameters: [address],
        },
        {
          reference: "level1",
          methodName: "LEVEL_PRICE",
          methodParameters: [1],
        },
        {
          reference: "level2",
          methodName: "LEVEL_PRICE",
          methodParameters: [2],
        },
        {
          reference: "level3",
          methodName: "LEVEL_PRICE",
          methodParameters: [3],
        },
        {
          reference: "level4",
          methodName: "LEVEL_PRICE",
          methodParameters: [4],
        },
        {
          reference: "level5",
          methodName: "LEVEL_PRICE",
          methodParameters: [5],
        },
        {
          reference: "totalbnb",
          methodName: "totalAmount",
        },
        {
          reference: "totaluser",
          methodName: "currentId",
        },
        {
          reference: "TokenAmount2",
          methodName: "viewClaimAmount",
          methodParameters: [address, 2],
        },
        {
          reference: "TokenAmount3",
          methodName: "viewClaimAmount",
          methodParameters: [address, 3],
        },
        {
          reference: "TokenAmount4",
          methodName: "viewClaimAmount",
          methodParameters: [address, 4],
        },
        {
          reference: "TokenAmount5",
          methodName: "viewClaimAmount",
          methodParameters: [address, 5],
        },
        {
          reference: "TotalPoolUsers",
          methodName: "totalPoolUsers",
        },
      ],
    },
  ];

  var ReferalTree = [
    {
      reference: "tree",
      contractAddress: config.contractaddress,
      abi: crytopumpABI,
      calls: [
        {
          reference: "referral",
          methodName: "viewUserReferral",
          methodParameters: [address],
        },
        {
          reference: "users",
          methodName: "users",
          methodParameters: [address],
        },
      ],
    },
  ];

  useEffect(() => {
    init();
    ReactTooltip.rebuild();
  }, []);

  const init = async () => {
    setloader(true);
    let { provider, address } = await getprovider();

    if (!localStorage.getItem("account") || localStorage.getItem("account") == undefined || typeof localStorage.getItem("account") == "undefined") {
      navigate("/");
      return;
    }

    console.log(address, config.contractaddress, provider, "proverssssss");
    let web3 = new Web3(provider);
    const multicall = new Multicall({
      web3Instance: web3,
      tryAggregate: false,
    });
    const dashboardresults = await multicall.call(CryptopumpContract);
    let web3js = new Web3(config.liverpcUrls);
    let USD_pricecontract = new web3js.eth.Contract(bnb_usdABI, config.bnb_usd);
    let usdpriceval = await USD_pricecontract.methods.latestAnswer().call();
    console.log(usdpriceval, "pricessssss");
    let convert = usdpriceval / 1e8;
    setusdPrice(convert);
    const userinfo = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      0
    );
    const level1info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      1
    );
    const level2info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      2
    );
    const level3info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      3
    );
    const level4info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      4
    );
    const level5info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      5
    );
    const gettotal = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      6
    );
    const gettotaluser = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      7
    );
    const token2info = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      8
    );
    const token3info = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      9
    );
    const token4info = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      10
    );
    const token5info = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      11
    );
    const poolusers = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      12
    );
    settotalPoolUsers(poolusers);
    console.log(poolusers, "poolusers")
    console.log(parseInt(level1info.hex), "level1info");
    setlevel1price(level1info);
    setlevel2price(level2info);
    setlevel3price(level3info);
    setlevel4price(level4info);
    setlevel5price(level5info);
    // gettotal = gettotal/1e18;
    let claimamount = {
      'token2': parseInt(token2info[0].hex),
      'token3': parseInt(token3info[0].hex),
      'token4': parseInt(token4info[0].hex),
      'token5': parseInt(token5info[0].hex),
    }
    settokenamount(claimamount);

    settotalbnb(gettotal);
    settotaluser(gettotaluser);
    setuserdetails(userinfo);
    console.log(userinfo, "userinfo");
    console.log(gettotal, "totallllllll");
    console.log(
      userinfo[0],
      parseInt(userinfo[2].hex),
      parseInt(userinfo[3].hex),
      parseInt(userinfo[4].hex),
      parseInt(userinfo[6].hex),
      parseInt(userinfo[7]),
      "dashboardinforsss"
    );
    let level = `Level ${parseInt(userinfo[3].hex)}`;
    let userid = parseInt(userinfo[1].hex);
    console.log();
    var rewards = parseInt(userinfo[6].hex)
    if (rewards > 0) {
      var rewardAmt = rewards / 10 ** 18;
      setReward(rewardAmt.toFixed(6))
    }

    let data = {
      levelValue: level,
      userId: userid
    };
    // const { status, message, result } = await allLevel(data);
    // console.log(result, "reslutttt");

    if (!userinfo[0]) {
      navigate("/");
    }

    // setallData(result);

    setrootId(parseInt(userinfo[1].hex));
    referalTreeLevelNext(
      web3,
      [address],
      0,
      setfirstTree,
      setsecondTree,
      setthirdTree,
      setfirstTreelevel,
      setsecondTreelevel,
      setthirdTreelevel
    );
    let req = { "address": address };
    const history = await transactionHistory(req);
    console.log(history, "data")
    settxhistory(history.data);
    setloader(false);
    if (parseInt(userinfo[3].hex) > 2) {
      if(parseInt(userinfo[3].hex) == 5){
        await gettradetoken()
      }else{
      setTimeout(async() => {
        await gettradetoken()
      }, 300000);
    }
  }
  };
  const gettradetoken = async () => {
    let tokens = await getTradecoin();
    if (tokens) {
      console.log(tokens, "tokens-----------------trade")
      settradetoken(tokens.result[0]);
    }
  }
  const ClaimToken = async (level) => {
    setclaimstatus(level);
    let { provider, address } = await getprovider();
    let web3 = new Web3(provider);
    let CryptopumpContract = new web3.eth.Contract(
      crytopumpABI,
      config.contractaddress
    );
    try {
      let res = await CryptopumpContract.methods
        .claimToken(level)
        .send({ from: address });
      console.log(res, "res");
      if (res) {
        toastAlert("success", "Claim token successfully", "claimtoken");
        setclaimstatus(0);
        let name = `token${level}`;
        let statusData = { ...tokenamount, ...{ [name]: 0 } }
        settokenamount(statusData)

      }
    } catch (err) {
      setclaimstatus(0)
      toastAlert("error", "Claim token failed", "claimtoken");
    }
  }

  const ClaimTokenOlduser = async (level) => {
    setclaimstatus(level);
    let { provider, address } = await getprovider();
    let web3 = new Web3(provider);
    let CryptopumpContract = new web3.eth.Contract(
      crytopumpABI,
      config.contractaddress
    );
    try {
      let res = await CryptopumpContract.methods
        .claimLevelReward(level)
        .send({ from: address });
      console.log(res, "res");
      if (res) {
        toastAlert("success", "Claim token successfully", "claimtoken");
        setclaimstatus(0);
        let name = `token${level}`;
        let statusData = { ...tokenamount, ...{ [name]: 0 } }
        settokenamount(statusData)
        window.location.reload()
      }
    } catch (err) {
      setclaimstatus(0)
      toastAlert("error", "Claim token failed", "claimtoken");
    }
  }

  const ClaimReward = async () => {
    setrewardstatus(true);
    let { provider, address } = await getprovider();
    let web3 = new Web3(provider);
    let CryptopumpContract = new web3.eth.Contract(
      crytopumpABI,
      config.contractaddress
    );
    try {
      let res = await CryptopumpContract.methods
        .claimRoyalty()
        .send({ from: address });
      console.log(res, "res");
      if (res) {
        toastAlert("success", "Reward Claim successfully", "claimtoken");
        setrewardstatus(false);
        setReward(0)
      }
    } catch (err) {
      setrewardstatus(false);
      toastAlert("error", "Reward Claim failed", "claimtoken");
    }
  }
  const BuyLevel = async (level, price) => {
    console.log(level, price, "myvalues---18")
    try {
      setbuystatus(level)
      let { provider, address } = await getprovider();
      if (level == parseInt(userdetails[3].hex) + 1) {
        let web3 = new Web3(provider);
        let bal = await web3.eth.getBalance(address);
        let val = web3.utils.fromWei(bal);
        let CryptopumpContract = new web3.eth.Contract(
          crytopumpABI,
          config.contractaddress
        );
        let estimategas = await CryptopumpContract.methods
          .buyLevel(level)
          .estimateGas({ value: price, from: address }).catch((err) => {
            console.log(err, 'error')
            setbuystatus(0)
            var message = (err && err.message) ? err.message : ""
            var position = message.search("insufficient funds for gas");
            if (position > 0) {
              toastAlert('error', "Insuffucient funds for gas fee", 'buylevel');
            } else {
              toastAlert('error', "Buy Level failed.!", 'buylevel');
            }
            return;
          })
        estimategas = estimategas + 1000;
        estimategas = estimategas / 10 ** 8;
        var userbalance = parseFloat(val);
        console.log(estimategas, 'estimategasestimategas', userbalance);
        if (userbalance >= estimategas) {
          let res = await CryptopumpContract.methods
            .buyLevel(level)
            .send({ value: price, from: address });
          console.log(res, 'resres--resres')
          let moneyforlevel = res.events.getMoneyForLevelEvent
            ? res.events.getMoneyForLevelEvent.returnValues
            : {};
          let reqdata = {
            address: address,
            referId: moneyforlevel.ReferrerId,
            level: level,
            levelPrice: parseInt(moneyforlevel.LevelPrice) / 1e18,
            passedup: res.events.lostMoneyForLevelEvent
              ? res.events.lostMoneyForLevelEvent.returnValues
              : "",
            userId: moneyforlevel.UserId,
            referrerAddress: moneyforlevel.ReferrerAddress,
            txnhash: res.transactionHash,
            adminfee: parseInt(moneyforlevel.Adminfee) / 1e18,
          };
          console.log(reqdata, "reqdata");
          let { success, message } = await buynewLevel(reqdata);
          console.log(res, "response of buylevel");
          toastAlert("success", "Level buyed successfully", "buylevel");
          setloader(false);
          setbuystatus(0);
          window.location.reload();
        } else {
          setbuystatus(0)
          toastAlert("error", "Your are not reach level");
        }
      } else {
        setbuystatus(0)
        toastAlert("error", "Buy Level failed", "buylevel");
      }
    } catch (err) {
      console.log(err, "catcherror")
      setbuystatus(0)
      toastAlert("error", "Buy Level failed", "buylevel");
    }


  };

  const search = async (e) => {
    let { provider, address } = await getprovider();
    let web3 = new Web3(provider);
    let CryptopumpContract = new web3.eth.Contract(
      crytopumpABI,
      config.contractaddress
    );
    let head = await CryptopumpContract.methods.userList(SearchID).call();
    setrootId(SearchID);
    if (head != zeroAddr && !validation) {
      referalTreeLevelNext(
        web3,
        [head],
        0,
        setfirstTree,
        setsecondTree,
        setthirdTree,
        setfirstTreelevel,
        setsecondTreelevel,
        setthirdTreelevel
      );
    }
  };

  const searchchange = (e) => {
    if (parseInt(totaluser[0].hex) >= e.target.value) {
      setSearchID(e.target.value);
      setvalidation(false);
    } else {
      setvalidation(true);
    }
  };

  // $(window).on("load", function () {
  //   $("#tradesignal").modal("show");
  // });

  function closeModal() {
    localStorage.removeItem('showpopup')
  }

  async function selectTree(userid) {
    if (userid && userid > 0) {
      let { provider } = await getprovider();
      let web3 = new Web3(provider);
      let CryptopumpContract = new web3.eth.Contract(
        crytopumpABI,
        config.contractaddress
      );
      let head = await CryptopumpContract.methods.userList(userid).call();
      setrootId(userid);
      if (head != zeroAddr && !validation) {
        referalTreeLevelNext(
          web3,
          [head],
          0,
          setfirstTree,
          setsecondTree,
          setthirdTree,
          setfirstTreelevel,
          setsecondTreelevel,
          setthirdTreelevel
        );
      }
    }


  }
  if (localStorage.getItem('showpopup') && localStorage.getItem('showpopup') == "yes") {
    console.log('********************')
    window.$('#tradesignal').modal('show')
  }
  console.log(Reward, "rewards")
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <ReactTooltip />
      <div className="inner_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="d-flex justify-content-end mb-5">
                {Reward > 0 &&
                  <button
                    className="claimGlown d-flex align-items-center"
                    disabled={rewardstatus ? "true" : ""}
                    onClick={ClaimReward}
                  >
                    {rewardstatus && <i className="fa fa-spinner fa-spin mr-2"></i>} Claim Token for Upgrading
                  </button>
                }
              </div> */}
              <div className="title_flex">
                <h2 className="inner_title">Dashboard</h2> <>
                  {/* {allData &&
                    allData.length > 0 &&
                    allData.map((item) => {
                      return (
                        <>
                          <div
                            class="modal fade primary_modal"
                            id="tradesignal"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="tradesignalTitle"
                            aria-hidden="true"
                            data-target="#tradesignal"
                            data-backdrop="static"
                          >
                            <div
                              class="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="exampleModalLongTitle"
                                  >
                                    Exclusive For You!
                                  </h5>
                                  <button
                                    type="button"
                                    class="close btn-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => closeModal()}
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <div class="commision_table_div">
                                    <table class="table commision_table mt-0">
                                      <thead>
                                        <tr>
                                          <th>Pair token</th>
                                          <th>{item.pairToken}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Time trade</td>
                                          <td>{item.timeTrade}</td>
                                        </tr>
                                        <tr>
                                          <td>Time</td>
                                          <td>{item.time} UTC</td>
                                        </tr>
                                        <tr>
                                          <td>Type</td>
                                          <td>{item.type}</td>
                                        </tr>
                                        <tr>
                                          <td>Price</td>
                                          <td>{item.price}</td>
                                        </tr>
                                        <tr>
                                          <td>Exchange</td>
                                          <td>{item.exchange}</td>
                                        </tr>
                                        <tr>
                                          <td>Description</td>
                                          <td>{item.description}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })} */}
                </>


                <div className="wallet_div">
                  <label>Your Wallet</label>
                  <div className="inp_grp">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      defaultValue={`${(address) ? address.substring(
                        0,
                        20
                      ) : ""}....${(address) ? address.substring(address.length - 3) : ""}`}
                    />
                    <img
                      src={require("../assets/images/copy_icon.png")}
                      data-iscapture={true}
                      data-tip="copy address"
                      onClick={() => {
                        copy(address);
                        setcopytxt(true);
                        setTimeout(function () {
                          setcopytxt(false);
                        }, 800)
                      }}
                      className="copy_icon inp_append"
                      alt="Copy"
                    />
                  </div>
                  {copytxt && <p style={{ "color": "green" }}>Copied</p>}
                </div>
              </div>

              <div className="row dashboard_top_wrap">
                {tradetoken && <div className="col-lg-12">
                  <div className="primary_box dash_box exclusive_box_wrap">
                    <div className="exclusive_box">
                      <img src={require("../assets/images/exclusive.png")} className="img-fluid" />
                      <div className="exclusive_box_content">
                        <div>
                          <span>Pair</span>
                          <span>{tradetoken.coin_symbol}</span>
                        </div>
                        <div>
                          <span>Pair Listing Platform</span>
                          <span>{tradetoken.platform}</span>
                        </div>
                        <div>
                          <span>Pair List Link</span>
                          <a href={tradetoken.listing_link} target="_blank">{tradetoken.coin_symbol}</a>
                        </div>
                        <div>
                          <span>Description</span>
                          <span>{tradetoken.coin_description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
                <div className="col-lg-12">
                  <div className="primary_box dash_box">
                    <div className="dashbox_middle_top">
                      <div>
                        {totalPoolUsers && <h2>{parseInt(totalPoolUsers[0].hex)}</h2>}
                        <h4>Total Level 5</h4>
                      </div>
                      <div>
                        {userdetails && <h2>{parseInt(userdetails[7].hex)}%</h2>}
                        <h4>Individual Percentage</h4>
                      </div>
                      <div>
                        {<h2>{Reward} cpump</h2>}
                        <h4>Cpump</h4>
                      </div>
                      {Reward > 0 && <button
                        className="claimGlown d-flex align-items-center mt-2 mb-2"
                        disabled={rewardstatus ? "true" : ""}
                        onClick={ClaimReward}>
                        {rewardstatus && <i className="fa fa-spinner fa-spin mr-2"></i>}Claim</button>}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="primary_box dash_box">
                    <div className="dash_box_left">
                      <p>Your ID</p>
                      {userdetails && userdetails.length > 0 && (
                        <h3>ID {parseInt(userdetails[1].hex)}</h3>
                      )}
                      {/* <div className='d-flex align-items-center'>
                          <img src={require("../assets/images/id_icon.png")} className='img-fluid mr-3' alt='Icon' />
                          <h5>10</h5>
                        </div> */}
                    </div>
                    <div className="dash_box_left">
                      <p>Balance in BNB</p>
                      {userdetails && userdetails.length > 0 && (
                        <h3>{parseInt(userdetails[4].hex) / 1e18}</h3>
                      )}
                    </div>
                    <div className="dash_box_left">
                      <p>USD Balance</p>
                      {userdetails && usdPirce && (
                        <h3>
                          $ {(userdetails && userdetails[4] && userdetails[4].hex) ? ((parseInt(userdetails[4].hex) / 1e18) * usdPirce).toFixed(8) : 0}
                        </h3>
                      )}
                    </div>
                    <div className="dash_box_left">
                      {usdPirce && (
                        <h4>1 BNB = $ {parseFloat(usdPirce).toFixed(2)}</h4>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            {totaluser && <h2>{parseInt(totaluser[0].hex)}</h2>}
                            <h4>Total Participants</h4>
                          </div>
                          <div>
                            {totalbnb && (
                              <h2>{(totalbnb && totalbnb[0] && totalbnb[0].hex) ? (parseInt(totalbnb[0].hex) / 1e18).toFixed(6) : 0} BNB</h2>
                            )}
                            <h4>Participants Earnings</h4>
                          </div>
                          <div>
                            {totalbnb && (
                              <h2>
                                {(totalbnb && totalbnb[0] && totalbnb[0].hex) ? ((parseInt(totalbnb[0].hex) / 1e18) * usdPirce).toFixed(6) : ""}{" "}
                                USD
                              </h2>
                            )}
                            <h4>USD Earnings</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_bottom">
                          <h2>Affiliate Link</h2>
                          {userdetails && (
                            <div className="inp_grp">
                              <input
                                type="text"
                                className="form-control primary_inp affiliate_link"
                                defaultValue={config.fronturl}
                              />
                              <img
                                src={require("../assets/images/copy_icon.png")}
                                data-tip="Refer Link"
                                onClick={() => {
                                  copy(
                                    `${config.fronturl}register/${parseInt(
                                      userdetails[1].hex
                                    )}`
                                  );
                                  setcopytxt1(true);
                                  setTimeout(function () {
                                    setcopytxt1(false);
                                  }, 800)
                                }}
                                className="copy_icon inp_append"
                                alt="Copy"
                              />
                              {copytxt1 && <p style={{ "color": "green" }}>Copied</p>}
                            </div>
                          )}
                          <ul className="footer_social_links">
                            {userdetails && (
                              <InlineShareButtons
                                config={{
                                  alignment: "center",
                                  //color: 'social',
                                  enabled: true,
                                  font_size: 16,
                                  language: "en",
                                  networks: [
                                    "facebook",
                                    "twitter",
                                    "linkedin",
                                    "telegram",
                                  ],
                                  padding: 12,
                                  radius: 100,
                                  show_total: false,
                                  size: 30,
                                  url: `${config.fronturl}register/${parseInt(
                                    userdetails[1].hex
                                  )}`,
                                  description:
                                    "The most innovative 100% Decentralized Matrix Platform",
                                  title: "CryptoPump",
                                  message: "CryptoPump",
                                  subject: "CryptoPump",
                                  username: "CryptoPump",
                                }}
                              />
                            )}
                            {/* <li><a href="javascript:void(0)" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="javascript:void(0)" target="_blank"><i className="fab fa-twitter"></i></a></li>
                              <li><a href="javascript:void(0)" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                              <li><a href="javascript:void(0)" target="_blank"><i className="fab fa-telegram-plane"></i></a></li> */}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_bottom">
                          <h2>Your Upliner ID</h2>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../assets/images/id_icon.png")}
                              className="img-fluid mr-3"
                              alt="Icon"
                            />
                            {userdetails && userdetails.length > 0 && (
                              <h5 className="mb-0">
                                {parseInt(userdetails[2].hex)}
                              </h5>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_bottom">
                          <h2>Contract Address</h2>
                          <div className="inp_grp">
                            <input
                              type="text"
                              className="form-control primary_inp"
                              defaultValue={`${(config.contractaddress) ? config.contractaddress.substring(
                                0,
                                10
                              ) : ""}....${(config.contractaddress) ? config.contractaddress.substring(
                                config.contractaddress.length - 3
                              ) : ""}`}
                            />
                            <img
                              src={require("../assets/images/copy_icon.png")}
                              className="copy_icon inp_append"
                              data-tip="copy contract"
                              onClick={() => {
                                copy(config.contractaddress);

                                setcopytxt2(true);
                                setTimeout(function () {
                                  setcopytxt2(false);
                                }, 800)

                              }}
                              alt="Copy"
                            />
                            {copytxt2 && <p style={{ "color": "green" }}>Copied</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h2>Packages</h2>
                  <div className="level_panel">
                    <div className="level_div primary_box level_div_active">
                      <h4>Level 1</h4>
                      {level1price && (
                        <h3>{parseInt(level1price.hex) / 1e18}</h3>
                      )}
                      {userdetails && parseInt(userdetails[3].hex) >= 1 ? (
                        <button className="primary_btn activated_btn" disabled="true">
                          Activated
                        </button>
                      ) : (
                        <button className="primary_btn">Buy</button>
                      )}
                    </div>
                    {userdetails && parseInt(userdetails[3].hex) >= 1 ? (
                      <div className="level_div primary_box level_div_active">
                        <h4>Level 2</h4>
                        {level2price && (
                          <h3>{parseInt(level2price.hex) / 1e18}</h3>
                        )}
                        {parseInt(userdetails[3].hex) >= 2 ? (
                          <button className="primary_btn activated_btn" disabled="true">
                            Activated
                          </button>
                        ) : (
                          <button
                            className={(buystatus) ? "primary_btn" : "buttonGlown"}
                            onClick={() =>
                              BuyLevel(2, parseInt(level2price.hex))
                            }
                            disabled={(buystatus) ? "disabled" : ""}
                          >
                            Buy
                          </button>
                        )}
                        {buystatus == 2 &&
                          <ReactLoading type={"bars"} color={"#2e80c0"} />
                        }
                        {tokenamount.token2 > 0 ? tokenamount.token2 > 0 && (
                          <button
                            className="primary_btn mt-4"
                            onClick={() =>
                              ClaimToken(2)
                            }
                            disabled={(claimstatus == 2) ? "disabled" : ""}
                          >
                            Claim Token
                          </button>
                        ) :
                          !userdetails[8] && parseInt(userdetails[3].hex) >= 2 && (
                            <button
                              className="primary_btn mt-4"
                              onClick={() =>
                                ClaimTokenOlduser(2)
                              }
                              disabled={(claimstatus == 2) ? "disabled" : ""}
                            >
                              Claim Token
                            </button>
                          )}
                        {claimstatus == 2 &&
                          <ReactLoading type={"bars"} color={"#2e80c0"} />
                        }
                      </div>
                    ) : (
                      <div className="level_div primary_box">
                        <h4>Level 2</h4>
                        {level2price && (
                          <h3>{parseInt(level2price.hex) / 1e18}</h3>
                        )}
                        <button className="primary_btn">Buy</button>
                      </div>
                    )}
                    {userdetails && parseInt(userdetails[3].hex) >= 2 ? (
                      <div className="level_div primary_box level_div_active">
                        <h4>Level 3</h4>
                        {level2price && (
                          <h3>{parseInt(level3price.hex) / 1e18}</h3>
                        )}
                        {parseInt(userdetails[3].hex) >= 3 ? (
                          <button className="primary_btn activated_btn" disabled="true">
                            Activated
                          </button>
                        ) : (
                          <button
                            className={(buystatus) ? "primary_btn" : "buttonGlown"}
                            onClick={() =>
                              BuyLevel(3, parseInt(level3price.hex))
                            }
                            disabled={(buystatus) ? "disabled" : ""}
                          >
                            Buy
                          </button>
                        )}
                        {buystatus == 3 &&
                          <ReactLoading type={"bars"} color={"#2e80c0"} />
                        }
                        {/* {tokenamount.token3 > 0&&(
                          <button
                            className="primary_btn mt-4"
                            onClick={() =>
                              ClaimToken(3)
                            }
                            disabled={(claimstatus == 3) ? "disabled" : ""}
                          >
                            Claim Token
                          </button>
                        )}
                        {claimstatus == 3 &&
                          <ReactLoading type={"bars"} color={"#2e80c0"} />
                        } */}
                      </div>
                    ) : (
                      <div className="level_div primary_box">
                        <h4>Level 3</h4>
                        {level3price && (
                          <h3>{parseInt(level3price.hex) / 1e18}</h3>
                        )}
                        <button className="primary_btn">Buy</button>
                      </div>
                    )}
                    {userdetails && parseInt(userdetails[3].hex) >= 3 ? (
                      <div className="level_div primary_box level_div_active">
                        <h4>Level 4</h4>
                        {level4price && (
                          <h3>{parseInt(level4price.hex) / 1e18}</h3>
                        )}
                        {parseInt(userdetails[3].hex) >= 4 ? (
                          <button className="primary_btn activated_btn" disabled="true">
                            Activated
                          </button>
                        ) : (
                          <button
                            className={(buystatus) ? "primary_btn" : "buttonGlown"}
                            onClick={() =>
                              BuyLevel(4, parseInt(level4price.hex))
                            }
                            disabled={(buystatus) ? "disabled" : ""}
                          >
                            Buy
                          </button>
                        )}
                        {buystatus == 4 &&
                          <ReactLoading type={"bars"} color={"#2e80c0"} />
                        }
                        {tokenamount.token4 > 0 ? tokenamount.token4 > 0 && (
                          <button
                            className="primary_btn mt-4"
                            onClick={() =>
                              ClaimToken(4)
                            }
                            disabled={(claimstatus == 4) ? "disabled" : ""}
                          >
                            Claim Token
                          </button>
                        ) : !userdetails[9] && parseInt(userdetails[3].hex) >= 4 && (
                          <button
                            className="primary_btn mt-4"
                            onClick={() =>
                              ClaimTokenOlduser(4)
                            }
                            disabled={(claimstatus == 4) ? "disabled" : ""}
                          >
                            Claim Token
                          </button>
                        )}
                        {claimstatus == 4 &&
                          <ReactLoading type={"bars"} color={"#2e80c0"} />
                        }
                      </div>
                    ) : (
                      <div className="level_div primary_box">
                        <h4>Level 4</h4>
                        {level4price && (
                          <h3>{parseInt(level4price.hex) / 1e18}</h3>
                        )}
                        <button className="primary_btn">Buy</button>
                      </div>
                    )}
                    {userdetails && parseInt(userdetails[3].hex) >= 4 ? (
                      <div className="level_div primary_box level_div_active">
                        <h4>Level 5</h4>
                        {level5price && (
                          <h3>{parseInt(level5price.hex) / 1e18}</h3>
                        )}
                        {parseInt(userdetails[3].hex) >= 5 ? (
                          <button className="primary_btn activated_btn" disabled="true">
                            Activated
                          </button>
                        ) : (
                          <button
                            className={(buystatus) ? "primary_btn" : "buttonGlown"}
                            onClick={() =>
                              BuyLevel(5, parseInt(level5price.hex))
                            }
                            disabled={(buystatus) ? "disabled" : ""}
                          >
                            Buy
                          </button>
                        )}
                        {buystatus == 5 &&
                          <ReactLoading type={"bars"} color={"#2e80c0"} />
                        }
                        {/* {tokenamount.token5 > 0&&(
                          <button
                            className="primary_btn mt-4"
                            onClick={() =>
                              ClaimToken(5)
                            }
                            disabled={(claimstatus == 5) ? "disabled" : ""}
                          >
                            Claim Token
                          </button>
                        )}
                        {claimstatus == 5 &&
                          <ReactLoading type={"bars"} color={"#2e80c0"} />
                        } */}
                      </div>
                    ) : (
                      <div className="level_div primary_box">
                        <h4>Level 5</h4>
                        {level5price && (
                          <h3>{parseInt(level5price.hex) / 1e18}</h3>
                        )}
                        <button className="primary_btn">Buy</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="inp_grp search_div">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      onChange={searchchange}
                      placeholder="Search by id"
                    />
                    <button onClick={search}>Search</button>
                  </div>
                  {validation && <p className="text-center mb-0 mt-5" style={{ "color": "red" }}>Enter Valid ID</p>}
                  <div className="plan_wrapper">
                    <p className="text-center mb-0 mt-5">ID {rootId}</p>
                    <div className="plan_pool_head">
                      <i className="far fa-user"></i>
                    </div>

                    {/* First Row */}
                    {firstTreelevel &&
                      firstTreelevel.length > 0 &&
                      firstTreelevel.map((first, i) => {

                        var toolTipId = "first0-" + first[0].id;
                        toolTipId = toolTipId.toString();

                        var toolTipId1 = "first1-" + first[1].id;
                        toolTipId1 = toolTipId1.toString();

                        return (
                          <div className="plan_pool_first_row">
                            <div
                              className={
                                first[0].id > 0
                                  ? "plan_pool_head_child plan_pool_head_child_active"
                                  : "plan_pool_head_child"
                              }
                              data-tip
                              data-for={toolTipId}
                              onClick={() => selectTree(first[0].id)}
                            >
                              <i className="far fa-user"></i>
                            </div>
                            <ReactTooltip
                              id={toolTipId}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              className="custom_tooltip"
                              place="top"
                            >
                              <p className="mb-0">ID {first[0].id} Level {first[0].level}</p>
                            </ReactTooltip>
                            {console.log(first[0].id, 'first')}
                            <div
                              className={
                                first[1].id > 0
                                  ? "plan_pool_head_child plan_pool_head_child_active"
                                  : "plan_pool_head_child"
                              }
                              data-tip
                              data-for={toolTipId1}
                              onClick={() => selectTree(first[1].id)}
                            >
                              <i className="far fa-user"></i>
                            </div>
                            <ReactTooltip
                              id={toolTipId1}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              className="custom_tooltip"
                              place="top"
                            >
                              <p className="mb-0">ID {first[1].id} Level {first[1].level}</p>
                            </ReactTooltip>
                          </div>
                        );
                      })}

                    {/* :<ReactLoading type={"spinningBubbles"} color={"#ffffff"} height={'10%'} width={'10%'} />} */}

                    {/* Second Row */}
                    <div className="plan_pool_second_row">
                      {secondTreelevel &&
                        secondTreelevel.length > 0 &&
                        secondTreelevel.map((second, i) => {

                          var toolTipId3 = "second0-" + second[0].id + i;
                          toolTipId3 = toolTipId3.toString();

                          var toolTipId4 = "second1-" + second[1].id + i;
                          toolTipId4 = toolTipId4.toString();

                          return (
                            <div>
                              <div
                                className={
                                  second[0].id > 0
                                    ? "plan_pool_head_child plan_pool_head_child_active"
                                    : "plan_pool_head_child"
                                }
                                data-tip
                                data-for={toolTipId3}
                                onClick={() => selectTree(second[0].id)}
                              >
                                <i className="far fa-user"></i>
                              </div>
                              <ReactTooltip
                                id={toolTipId3}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                className="custom_tooltip"
                                place="top"
                              >
                                <p className="mb-0">ID {second[0].id} Level {second[0].level}</p>
                              </ReactTooltip>
                              <div
                                className={
                                  second[1].id > 0
                                    ? "plan_pool_head_child plan_pool_head_child_active"
                                    : "plan_pool_head_child"
                                }
                                data-tip
                                data-for={toolTipId4}
                                onClick={() => selectTree(second[1].id)}
                              >
                                <i className="far fa-user"></i>
                              </div>
                              <ReactTooltip
                                id={toolTipId4}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                className="custom_tooltip"
                                place="top"
                              >
                                <p className="mb-0">ID {second[1].id} Level {second[1].level}</p>
                              </ReactTooltip>
                            </div>
                          );
                        })}
                    </div>

                    {/* Third Row */}
                    <div className="plan_pool_third_row">
                      {thirdTreelevel &&
                        thirdTreelevel.length > 0 &&
                        thirdTreelevel.map((third, i) => {

                          var toolTipId5 = "third0-" + third[0].id + i;
                          toolTipId5 = toolTipId5.toString();

                          var toolTipId6 = "third1-" + third[1].id + i;
                          toolTipId6 = toolTipId6.toString();

                          return (
                            <div>
                              <div
                                className={
                                  third[0].id > 0
                                    ? "plan_pool_head_child plan_pool_head_child_active"
                                    : "plan_pool_head_child"
                                }
                                data-tip
                                data-for={toolTipId5}
                                onClick={() => selectTree(third[0].id)}
                              >
                                <i className="far fa-user"></i>
                              </div>
                              <ReactTooltip
                                id={toolTipId5}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                className="custom_tooltip"
                                place="top"
                              >
                                <p className="mb-0">ID {third[0].id} Level {third[0].level}</p>
                              </ReactTooltip>

                              <div
                                className={
                                  third[1].id > 0
                                    ? "plan_pool_head_child plan_pool_head_child_active"
                                    : "plan_pool_head_child"
                                }
                                data-tip
                                data-for={toolTipId6}
                                onClick={() => selectTree(third[1].id)}
                              >
                                <i className="far fa-user"></i>
                              </div>
                              <ReactTooltip
                                id={toolTipId6}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                className="custom_tooltip"
                                place="top"
                              >
                                <p className="mb-0">ID {third[1].id} Level {third[1].level}</p>
                              </ReactTooltip>
                            </div>
                          );
                        })}
                    </div>

                    {/* Fourth Row */}
                    {/* <div className="plan_pool_fourth_row">
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                      </div> */}

                    {/* Fifth Row */}
                    {/* <div className="plan_pool_fifth_row">
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                        <div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                          <div className="plan_pool_head_child">
                            <i className="far fa-user"></i>
                          </div>
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <h2>Purchase History</h2>
                  <div className="primary_table">
                    <DataTable columns={columns} data={txhistory} noHeader pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={[5, 10, 15, 20]} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader_section_">
          <ReactLoading type={"bars"} color={"#ffffff"} className="loading" />
        </div>
      )}
      <Footer />
    </div>
  );
}
