import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseurl;


export const addcoin = async (data) => {
    try {
      console.log(baseUrl);
      const formData = new FormData();
        formData.append("logo", data.logo);
        formData.append("coin_name", data.coin_name);
        formData.append("coin_symbol", data.coin_symbol);
        formData.append("network", data.network);
        formData.append("presale", data.presale);
        formData.append("platform", data.platform);
        formData.append("listing_link", data.listing_link);
        formData.append("contract_address", data.contract_address);
        formData.append("category", data.category);
        formData.append("coin_description", data.coin_description);
        formData.append("website_link", data.website_link);
        formData.append("telegram_link", data.telegram_link);
        formData.append("discord_link", data.discord_link);
        formData.append("reddit_link", data.reddit_link);
        formData.append("telegram_contact", data.telegram_contact);
        formData.append("user_address", data.user_address);
      let respData = await axios({
        method: "post",
        url: `${baseUrl}/add-coin`,
        data: formData,
      });
      return {
        message: respData.data.message,
        status: respData.data.status,
        err: respData.data.err,
        data: respData.data.data,
      };
    } catch (err) {
      return {
        err: returnErr(err),
      };
    }
  }

  export const addcoinvote = async (data) => {
    try {
      console.log(data,'dattttaaaaa-------------');
      let respData = await axios({
        method:'post',
        url: `${baseUrl}/add-coin-vote`,
        data: data,
      });
      return {
        message: respData.data.message,
        status: respData.data.status,
        err: respData.data.err,
        data: respData.data.data,
      };
    } catch (err) {
      return {
        err: returnErr(err),
      };
    }
 }

  export const getcoinlist = async (data) => {
    try {
      console.log(baseUrl);
      let respData = await axios({
        method: "post",
        url: `${baseUrl}/get-coinlist`,
        data: data,
      });
      return {
        message: respData.data.message,
        status: respData.data.status,
        err: respData.data.err,
        data: respData.data.data,
      };
    } catch (err) {
      return {
        err: returnErr(err),
      };
    }
  }


  function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
      return err.response.data.errors;
    } else {
      return "";
    }
  }