let key = {};
if (process.env.NODE_ENV == "production") {
  //************* LIVE ************************************//
  const FRONT_URL = "https://www.cryptopump.club/";
  const backend_URL = "https://api.cryptopump.club";
  const Back_URL = "https://api.cryptopump.club/api";
  const NETWORKVERSION = "56";
  const LiveChainID = 56;
  const LiveRPCURL = "https://bscrpc.com/";
  const Contractaddress = "0x50078c34370e0D0b3E011c49feA0cf23827FFF48";
  const tokenaddress= "0x5c741F6CBa99738a371bc76033d73402Cd686902";  
  const BNBUSD_contract = "0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee";
  const bscscan = `https://bscscan.com/address/${Contractaddress}#code`;
  const bsctrx ="https://bscscan.com/tx/";
  const provider = "https://bscrpc.com/"; 
  const tokenpower = 4;
  const createpower = 1;
  const votingpower = '1';
  const coinvotepower = '100';
  //*****************DEMO *******************************//
  // const FRONT_URL = "https://cryptopump.wealwin.com/";
  // const backend_URL = "https://cryptopumpapi.wealwin.com"
  // const Back_URL = "https://cryptopumpapi.wealwin.com/api";
  // const NETWORKVERSION = "97";
  // const LiveChainID = 97;
  // const LiveRPCURL = "https://bscrpc.com/";
  // const Contractaddress = "0x2735CF294731Cf5a2e6F6dE33779ffc38C6cc8d0";
  // const tokenaddress= "0x0B5d163d5bfE1CA1F43cB2Ca59b230f692A5a50D";
  // const bscscan = `https://testnet.bscscan.com/address/${Contractaddress}#code`;
  // const bsctrx ="https://testnet.bscscan.com/tx/";
  // const BNBUSD_contract = "0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee";
  // const provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  // const tokenpower = 4;
  // const createpower = 1;
  // const votingpower = '1';
  // const coinvotepower = '100';

  key = {
    backurl : backend_URL,
    baseurl: Back_URL,
    fronturl: FRONT_URL,
    livechainid: LiveChainID,
    liverpcUrls: LiveRPCURL,
    networkVersion: NETWORKVERSION,
    contractaddress: Contractaddress,
    tokenaddress: tokenaddress,
    bscscan: bscscan,
    bsctrx:bsctrx,
    bnb_usd: BNBUSD_contract,
    provider: provider,
    tokenpower: tokenpower,
    votingpower: votingpower,
    createpower: createpower,
    coinvotepower:coinvotepower,
  };
} else if (process.env.NODE_ENV == "development") {
  console.log("Development config")
  const FRONT_URL = "http://localhost:3000/";
  const backend_URL = "http://localhost:2053" 
  const Back_URL = "http://localhost:2053/api";
  const NETWORKVERSION = "97";
  const LiveChainID = 97;
  const LiveRPCURL = "https://bscrpc.com/";
  const Contractaddress = "0x2735CF294731Cf5a2e6F6dE33779ffc38C6cc8d0";
  const tokenaddress= "0x0B5d163d5bfE1CA1F43cB2Ca59b230f692A5a50D";
  const bscscan = `https://testnet.bscscan.com/address/${Contractaddress}#code`;
  const bsctrx ="https://testnet.bscscan.com/tx/";
  const BNBUSD_contract = "0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee";
  const provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  const tokenpower = 4;
  const createpower = 1;
  const votingpower = '1';
  const coinvotepower = '100';

  key = {
    backurl : backend_URL,
    baseurl: Back_URL,
    fronturl: FRONT_URL,
    livechainid: LiveChainID,
    liverpcUrls: LiveRPCURL,
    networkVersion: NETWORKVERSION,
    contractaddress: Contractaddress,
    tokenaddress: tokenaddress,
    bscscan: bscscan,
    bsctrx: bsctrx,
    bnb_usd: BNBUSD_contract,
    provider: provider,
    tokenpower: tokenpower,
    votingpower: votingpower,
    createpower: createpower,
    coinvotepower:coinvotepower,
  };
}
export default key;
