import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";
import ReactTooltip from "react-tooltip";
import copy from "copy-to-clipboard";
import config from "../../config/config";
import tokenABI from "../../ABI/tokenABI.json";
import validation from "./validation.js";
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, getFormatMulticall } from "../../actions/comman";
import {
  buynewLevel,
  allLevel,
  transactionHistory,
  getvotingPower,
} from "../../actions/users";
import { createvoting } from "../../actions/users";
import { getprovider } from "../../actions/walletconnect.js";
import { toastAlert } from "../../helper/toastAlert.js";
import Web3 from "web3";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import bnb_usdABI from "../../ABI/bnb_usd.json";
import ReactLoading from "react-loading";
import { InlineShareButtons } from "sharethis-reactjs";
import $ from "jquery";
import { referalTreeLevelNext } from "../../pages/tree";
import DataTable from "react-data-table-component";
import moment from "moment";
import isEmpty from "../../helper/isEmpty.js";
import { addcoin } from "../../actions/coin";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialData = {
  logo: "",
  coin_name: "",
  coin_symbol: "",
  network: "",
  presale: "",
  platform: "",
  listing_link: "",
  contract_address: "",
  category: "",
  coin_description: "",
  website_link: "",
  telegram_link: "",
  discord_link: "",
  reddit_link: "",
  telegram_contact: "",
  user_address: "",
};

var priceinterval;

export default function Dashboard(props) {
  let [loader, setloader] = useState();
  const [formValue, setformValue] = useState(initialData);
  const [toValue, settoValue] = useState(initialData);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [title, settitle] = useState("");
  const [explanation, setexplanation] = React.useState("");
  const [values, setValues] = useState({ val: [] });
  const [Accounts, Set_Accounts] = useState("");
  const [votingpower, set_votingpower] = useState(0);
  const [process, setprocess] = useState(false);
  const [propsalval, setpropsalval] = useState("core");
  const [tokenbalance, settokenbalance] = React.useState({
    balance: 0,
    balanceOf: 0,
  });
  const [tokenpower, settokenpower] = React.useState(0);
  const [validateError, setValidateError] = useState({});
  const [filename,setFilename] = useState('')
  const [Filesize,setFilesize] = useState()


  useEffect(() => {
    // callclick();
    votepowercall();
    gettokenpower();
  }, []);

  const gettokenpower = async () => {
    let res = await getvotingPower();
    console.log(res, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr===========");
    set_votingpower(res.message[0].value);
    console.log(
      votingpower,
      "re-----------------------ssssssssssssssssssssssss"
    );
  };

  async function votepowercall() {
    let { provider, address } = await getprovider();
    if (address) {
      let web3 = new Web3(provider);
      var currAddress = address;
      let bal = await web3.eth.getBalance(address);
      let balance = web3.utils.fromWei(bal);
      let CryptopumpContract = new web3.eth.Contract(
        tokenABI,
        config.tokenaddress
      );
      var balanceOf = await CryptopumpContract.methods
        .balanceOf(address)
        .call();
      balanceOf = web3.utils.fromWei(balanceOf);
      settokenbalance({ balance: balance, balanceOf: balanceOf });
      var token_power = parseFloat(config.tokenpower) * parseFloat(balance);
      settokenpower(token_power);
    }
  }

  async function callclick() {
    setValues({ val: [...values.val, ""] });
  }

  const handleChangetitle = (e) => {
    settitle(e.target.value);
  };

  const onEditorChange = (evt) => {
    var description_text = evt.editor.getData();
    setexplanation(description_text);
  };
  function handleChangedescription(event) {
    setexplanation(event.target.value);
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setformValue(formData);
  };

  const handleChangeRadio = (e) => {
    // e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setformValue(formData);
  }

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const formData = { ...formValue, ...{ [name]: files[0] } };
    console.log(formData,'fffffffffffffffffffff-----------------');
    setformValue(formData);
    setFilename(formData.logo.name)
    setFilesize(returnFileSize(files[0].size))
  };

  function returnFileSize(number) {
    if (number < 1024) {
      return `${number} bytes`;
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`;
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`;
    }
  }

  const handleSubmit = async (e) => {
    try{
    setValidateError(validation(formValue));
    console.log(validateError,'validateErrorvalidateError');
    if (isEmpty(validation(formValue))) {
      setloader(true);
      let { provider, address } = await getprovider();
      if (address) {
        var web3 = new Web3(provider);
        var TokenContract = new web3.eth.Contract(
          tokenABI,
          config.tokenaddress
        );

        let balanceOf = await TokenContract.methods.balanceOf(address).call();
        balanceOf = web3.utils.fromWei(balanceOf);
        var myvotepower = parseFloat(tokenpower);
        if (votingpower <= parseFloat(balanceOf)) {
          console.log(votingpower,"votingpowervotingpower")
          let burntoken = web3.utils.toWei(votingpower, "ether");
          var estimategas = await TokenContract.methods
            .burn(burntoken)
            .estimateGas({ from: address })
            .catch((err) => {
              console.log(err, "err");
              var message = err && err.message ? err.message : "";
              var position = message.search("insufficient funds for gas");
              if (position > 0) {
                setloader(false);
                toastAlert("error", "Insuffucient funds for gas fee", "coin");
              } else {
                setloader(false);
                toastAlert("error", "Oops something went wrong.!", "coin");
              }

              setloader(false);
              return;
            });
          estimategas = estimategas + 1000;
          estimategas = estimategas / 10 ** 8;

          var userbalance = parseFloat(tokenbalance.balance);
          console.log(estimategas, "estimategasestimategas", userbalance);
          if (userbalance >= estimategas) {
            let burntoken = web3.utils.toWei(votingpower, "ether");

            try {
              let paytoken = await TokenContract.methods
                .burn(burntoken)
                .send({ from: address });
              let formData = { ...formValue, ...{ ["user_address"]: address } };
              let rescoin = await addcoin(formData);
              setTimeout(() => { window.location.href='/coin-list' }, 2000);  
              console.log(rescoin);
            } catch (err) {
              setloader(false);
              toastAlert("error", "User Rejected", "coin");
            }
            setloader(false);
            toastAlert("success", "Coin submitted successfully", "coin");
          } else {
            setloader(false);
            toastAlert("error", "You don't have a balance", "coin");
          }
        } else {
          setloader(false);
          toastAlert(
            "error",
            "You must have atleast " +
              votingpower +
              " voting power to add new coin ",
            "coin"
          );
        }
      } else {
        setloader(false);
        toastAlert("error", "Connect BSC Testnet", "coin");
      }
    } else {
      setloader(false);
      toastAlert("error", "Please enter Required field", "coin");
    }
  }catch(err){
    setloader(false)
    toastAlert("error","try again later");
    console.log(err,"error")
  }
  };

  function removeClick(i) {
    let vals = [...values.val];
    vals.splice(i, 1);
    console.log(vals);
    setValues({ val: vals });
  }

  const addClick = () => {
    setValues({ val: [...values.val, ""] });
  };

  function createInputs() {
    return values.val.map((el, i) => (
      <div className="form-group" key={i}>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{parseInt(i) + 1}</span>
          </div>
          <input
            type="text"
            class="primary_inp"
            onChange={handleChange.bind(i)}
            value={el}
          />
          <div class="input-group-append">
            <span class="input-group-text" onClick={() => removeClick(i)}>
              <i class="fas fa-times"></i>
            </span>
          </div>
        </div>
      </div>
    ));
  }

  function choiceValidation() {
    var isValid = true;
    var cow = 0;
    if (values.val.length > 0) {
      for (let i = 0; i < values.val.length; i++) {
        if (values.val[i] != "") {
          return true;
          break;
        } else {
          cow = parseInt(cow) + parseInt(1);
        }
        if (parseInt(cow) == values.val.length) {
          return false;
        }
      }
    } else {
      toastAlert("error", "Enter Choice for Voting", "error");
      return false;
    }
  }

  async function submit_publish() {
    let { provider, address } = await getprovider();
    if (address) {
      let web3 = new Web3(provider);
      let TokenContract = new web3.eth.Contract(tokenABI, config.tokenaddress);

      let balanceOf = await TokenContract.methods.balanceOf(address).call();
      balanceOf = web3.utils.fromWei(balanceOf);
      var myvotepower = parseFloat(tokenpower);
      if (myvotepower <= parseFloat(balanceOf)) {
        var startdate = moment(startDate).startOf("day").format();
        var enddate = moment(endDate).startOf("day").format();
        var starttime = moment(startTime).diff(
          moment().startOf("day"),
          "seconds"
        );
        var endtime = moment(endTime).diff(moment().startOf("day"), "seconds");
        var finalstartdate = moment(startdate)
          .add(starttime, "seconds")
          .format();
        var finalenddate = moment(enddate).add(endtime, "seconds").format();
        setprocess(true);
        if (address && address != "" && address != null) {
          var currAddress = address;
          if (title != "" && title != undefined && title != null) {
            if (
              explanation != "" &&
              explanation != undefined &&
              explanation != null
            ) {
              if (choiceValidation()) {
                var a = moment(finalstartdate);
                var b = moment(finalenddate);
                var now = moment();
                var finish = b.diff(a);
                if (
                  moment(finalenddate).isAfter(now) == true &&
                  finish != "" &&
                  parseInt(finish) > 0
                ) {
                  var createdata = {
                    question: title,
                    explanation: explanation,
                    choices: values,
                    startdate: finalstartdate,
                    enddate: finalenddate,
                    starttime: starttime,
                    endtime: endtime,
                    address: currAddress,
                    propsal: propsalval,
                  };

                  var restul = await createvoting(createdata);
                  toastAlert("success", "Published Successfully", "error");
                  window.location.reload();
                } else {
                  setprocess(false);
                  toastAlert("error", "Choose Valid End Date", "error");
                }
              } else {
                setprocess(false);
                toastAlert("error", "Enter Valid Choice", "error");
              }
            } else {
              setprocess(false);
              toastAlert("error", "Enter Valid Description", "error");
            }
          } else {
            setprocess(false);
            toastAlert("error", "Enter Valid Details", "error");
          }
        } else {
          setprocess(false);
          toastAlert("error", "Connect BSC Testnet", "excerciseerr");
        }
      } else {
        setprocess(false);
        toastAlert(
          "error",
          "You must have atleast " +
            config.createpower +
            " voting power to make a new proposal ",
          "excerciseerr"
        );
      }
    } else {
      setprocess(false);
      toastAlert("error", "Connect BSC Testnet", "excerciseerr");
    }
  }
  function propasalType(event) {
    setpropsalval(event.target.value);
  }

  const handleChangefile = (e) => {
    console.log(e.target);
  };
  console.log(formValue, "formvalue");
  console.log(validateError, "validateError");
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <ReactTooltip />
      <div className="inner_wrapper">
        <div className="inner_pageheader">
          <div className="inner_content_wrapper proposal_content_panel">
            <div className="container">
              <div className="row">
                <div className="col-xl-9 mx-auto">
                <div className="primary_box dash_box make_proposal_panel">
                    <div className="proposal_panel_left">
                      <form className="make_proposal_form">
                        <h2 className="text-center">Add your coin</h2>
                        <h6 className="text-center">Listing Request</h6>
                        

                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label for="title">Logo</label>
                            {/* <input
                              type="file"
                              className="form-control primary_inp"
                              id="logo"
                              name="logo"
                              placeholder="Enter your logo here"
                            /> */}
                            <div class="form-group">
                              <input type="file" name="logo" id="file" class="input-file" onChange={handleFileChange} accept="image/x-png,image/gif,image/jpeg,image/jpg" />
                              <label for="file" class="btn btn-tertiary js-labelFile">
                                <i class="icon fa fa-check"></i>
                                <span class="js-fileName d-block d-inline-block text-truncate">{filename ? filename : "Click to upload image"}</span>
                                {Filesize&&<span class="js-fileName d-block">{Filesize}</span>}
                              </label>
                            </div>
                            {validateError.logo && (
                              <p className="error-message">
                                {validateError.logo}
                              </p>
                            )}
                          </div>

                          <div className="form-group col-md-12">
                            <label for="title">
                              Coin Name <span className="importent">*</span>
                            </label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              name="coin_name"
                              id="title"
                              placeholder="Enter your coin name here"
                              onChange={handleChange}
                            />
                            {validateError.coin_name && (
                              <p className="error-message">
                                {validateError.coin_name}
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="title">
                              Coin Pair <span className="importent">*</span>
                            </label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              name="coin_symbol"
                              id="coin_symbol"
                              placeholder="Enter your coin pair here"
                              onChange={handleChange}
                            />
                            {validateError.coin_symbol && (
                              <p className="error-message">
                                {validateError.coin_symbol}
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="title">
                              Network/Chain<span className="importent">*</span>
                            </label>
                            <select
                              className="primary_inp form-control"
                              name="network"
                              onChange={handleChange}
                            >
                              <option value={"default"}>Select One</option>
                              <option value={"binance"}>
                                Binance Smart Chain (BSC)
                              </option>
                              <option value={"ethereum"}>
                                Ethereum Chain (ETH)
                              </option>
                              <option value={"cronos"}>
                                Cronos Chain (CRO)
                              </option>
                              <option value={"polygon"}>Polygon (Matic)</option>
                              <option value={"solana"}>Solana (SOL)</option>
                              <option value={"dogechain"}>
                                Dogechain (DC)
                              </option>
                              <option value={"ethw"}>ETHW</option>
                            </select>
                            {validateError.network && (
                              <p className="error-message">
                                {validateError.network}
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="presale">
                              Project in presale phase?
                              <span className="importent">*</span>
                            </label>
                            {/* <input type="input" className="form-control primary_inp" name="coin_symbol" id="coin_symbol" placeholder="Enter your coin symbol here"  onChange={handleChange} /> */}
                            <div className="radioFlex">
                              <div className="optionGroup">
                              <input
                                type="radio"
                                id="presale-no"
                                name="presale"
                                value="no"
                                onChange={handleChangeRadio}
                              />
                              <label for="presale-no">No</label>
                              </div>
                              <div className="optionGroup">
                              <input
                              type="radio"
                              id="presale-yes"
                              name="presale"
                              value="yes"
                              onChange={handleChangeRadio}
                            />
                            <label for="presale-yes">Yes</label>
                            </div>
                            </div>
                          

                            
                            
                            {validateError.presale && (
                              <p className="error-message">
                                {validateError.presale}
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="title">
                              Listing Platform
                              <span className="importent">*</span>
                            </label>
                            <select
                              className="primary_inp form-control"
                              name="platform"
                              onChange={handleChange}
                            >
                              <option value={"default"}>Select One</option>
                              <option value={"PancakeSwap"}>PancakeSwap</option>
                              <option value={"Yodeswap"}>Yodeswap</option>
                              <option value={"UniSwap"}>UniSwap</option>
                              <option value={"QuickSwap"}>QuickSwap</option>
                              <option value={"ApeSwap"}>ApeSwap</option>
                              <option value={"Biswap"}>Biswap</option>
                              <option value={"MMFinance"}>MM Finance</option>
                            </select>
                            {validateError.platform && (
                              <p className="error-message">
                                {validateError.platform}
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="title">Listing Link</label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              id="listing_link"
                              name="listing_link"
                              placeholder="Enter your Listing Link"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label for="title">
                              Contract Address{" "}
                              <span className="importent">*</span>
                            </label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              id="contract_address"
                              name="contract_address"
                              placeholder="Enter your contract address here"
                              onChange={handleChange}
                            />
                            {validateError.contract_address && (
                              <p className="error-message">
                                {validateError.contract_address}
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="category">
                              Category<span className="importent">*</span>
                            </label>
                            <select
                              className="primary_inp form-control"
                              id="category"
                              name="category"
                              onChange={handleChange}
                            >
                              <option value={"default"}>Select One</option>
                              <option value={"DeFi"}>DeFi</option>
                              <option value={"Gaming"}>Gaming</option>
                              <option value={"Metaverse"}>Metaverse</option>
                              <option value={"Yield Farm"}>Yield Farm</option>
                              <option value={"Launchpad"}>Launchpad</option>
                              <option value={"NFT"}>NFT</option>
                              <option value={"Meme"}>Meme</option>
                              <option value={"Gambling"}>Gambling</option>
                              <option value={"Adult"}>Adult</option>
                              <option value={"Music"}>Music</option>
                              <option value={"Exchange"}>Exchange</option>
                              <option value={"DApp"}>DApp</option>
                              <option value={"Play2Earn"}>Play2Earn</option>
                              <option value={"Move2Earn"}>Move2Earn</option>
                              <option value={"Web3"}>Web3</option>
                              <option value={"Other"}>Other</option>
                            </select>
                            {validateError.category && (
                              <p className="error-message">
                                {validateError.category}
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="coin_description">
                              Coin Description
                              <span className="importent">*</span>{" "}
                            </label>
                            <textarea
                              className="form-control primary_inp"
                              rows={3}
                              id="coin_description"
                              placeholder="Enter your coin description here"
                              name="coin_description"
                              onChange={handleChange}
                            ></textarea>
                            {/* <div className="desc_editor"><CKEditor id="explanation" data="<p></p>" onChange={onEditorChange} /></div> */}
                            {validateError.coin_description && (
                              <p className="error-message">
                                {validateError.coin_description}
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="website_link">
                              Website Link<span className="importent">*</span>
                            </label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              name="website_link"
                              id="website_link"
                              placeholder="Enter your website Link"
                              onChange={handleChange}
                            />
                             {validateError.website_link && (
                              <p className="error-message">
                                {validateError.website_link}
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="twitter_link">Twitter Link</label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              id="twitter_link"
                              name="twitter_link"
                              placeholder="Enter your twitter Link"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label for="telegram_link">Telegram Link</label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              id="telegram_link"
                              name="telegram_link"
                              placeholder="Enter your telegram Link"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label for="discord_link">Discord Link</label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              id="discord_link"
                              name="discord_link"
                              placeholder="Enter your discord Link"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label for="reddit_link">Reddit Link</label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              id="reddit_link"
                              name="reddit_link"
                              placeholder="Enter your reddit Link"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label for="telegram_contact">
                              Telegram Contact{" "}
                              <span className="importent">*</span>
                            </label>
                            <input
                              type="input"
                              className="form-control primary_inp"
                              name="telegram_contact"
                              id="telegram_contact"
                              placeholder="Enter your telegram contact"
                              onChange={handleChange}
                            />
                            {validateError.telegram_contact && (
                              <p className="error-message">
                                {validateError.telegram_contact}
                              </p>
                            )}
                          </div>
                          {/* <div className="form-group col-md-12">
                            <label for="choice1">Choices</label>
                            <div className="choice_inp">
                              {createInputs()}
                            </div> */}
                          {/*<input type="text" className="form-control primary_inp" placeholder="Input choice text" id="choice1" placeholder="Input choice text" />*/}
                          {/* </div> */}
                        </div>
                        <button
                          type="button"
                          className="primary_btn"
                          onMouseEnter={() => votepowercall()}
                          onClick={handleSubmit}
                        >
                          <i className="bi bi-plus"></i>Add Coin
                        </button>
                      </form>
                   
                  {/* <div className="col-lg-5"> */}
                  {/* <div className="proposal_panel_right">
                      <form className="make_proposal_form">
                        <h2>Actions</h2>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="sdate">Start Date</label>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} selectsStart startDate={startDate} endDate={endDate} className="form-control primary_inp text-center" id="sdate" />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="edate">End Date </label>
                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} className="form-control primary_inp text-center" id="edate" />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="stime">Start Time</label>
                            <DatePicker selected={startTime} onChange={(date) => setStartTime(date)} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" className="form-control primary_inp text-center" id="stime" />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="etime">End Time</label>
                            <DatePicker selected={endTime} onChange={(date) => setEndTime(date)} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" className="form-control primary_inp text-center" id="etime" />
                          </div>
                        </div>
                        <div className="proposal_creator_details">
                          <div>
                            <label>Creator:</label>
                            {localStorage.getItem("account") && localStorage.getItem("account") != null && localStorage.getItem("account") != "" ?
                              <label>{(localStorage.getItem("account")).substring(0, 4) + '.....' + (localStorage.getItem("account")).substring((localStorage.getItem("account")).length - 4, (localStorage.getItem("account")).length)}</label>
                              :
                              <label>---</label>
                            }
                          </div> */}
                  {/*<div>
                                <label>Snapshot:</label>
                                <label>123456789</label>
                              </div>*/}
                  {/* </div>
                        <div className="check_voting_power_div">
                          <p>{"You need at least " + config.createpower + " voting power to publish a proposal"}</p>
                        </div>
                        <div className="proposal_action_grp_btns">
                          <button type="button" className="primary_btn" data-toggle="modal" data-target="#voting_power_modal" onMouseEnter={() => votepowercall()}>Check Voting Power</button>
                          {localStorage.getItem("account") && localStorage.getItem("account") != null && localStorage.getItem("account") != "" ?
                            <button type="button" className="primary_btn" onMouseEnter={() => votepowercall()} onClick={submit_publish}>Publish</button>
                            :
                            <button className="primary_btn" disabled>Publish</button>
                          }
                        </div>
                      </form>
                    </div>
                  </div> */}
                </div>
              </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      {/* Voting Power Modal */}
      <div
        className="modal fade primary_modal"
        id="voting_power_modal"
        tabindex="-1"
        aria-labelledby="voting_power_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="voting_power_modalLabel">
                Voting Power
              </h5>
              <button
                type="button"
                class="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="voting_power_overview_top">
                <p>
                  Your voting power is detemined by the amount of Cryptopump you
                  held at the block detailed below. Cryptopump held in other
                  places does not contribute to your voting power.
                </p>
                <h4>Overview</h4>
                <div className="voting_power_overview_div">
                  <h5>Your Voting Power</h5>
                  <h5>{parseFloat(tokenpower).toFixed()}</h5>
                </div>
              </div>
              <div className="voting_power_overview_bottom">
                <div className="voting_power_overview_left">
                  <div className="voting_power_overview_detail">
                    <div>
                      <label>Wallet (Cryptopump)</label>
                      <label>{tokenbalance.balanceOf}</label>
                    </div>
                    {/*   <div>
                      <label>Referred Wallets</label>
                      <label>{parseFloat(referralpower).toFixed()}</label>
                    </div>
                    <div>
                      <label>Pool</label>
                      <label>1213.03</label>
                    </div>
                    <div>
                      <label>Farms</label>
                      <label>1.023</label>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="primary_btn w_sm_100_btn ml-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader_section_">
          <ReactLoading type={"bars"} color={"#ffffff"} className="loading" />
        </div>
      )}
      <Footer />
    </div>
  );
}
