import React, { useState } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { toastAlert } from "../helper/toastAlert";
import  config  from "../config/config";



const Wallet_Popup = ( { popupStatus, setPopupStatus, onClose } ) => {
let [account, setaccount] = useState();
    const closePopup = () => { 
        setPopupStatus(false);  
        if( onClose ) onClose();
    }
console.log("RpcUrl",config.liverpcUrls)
    async function connectMetamask() {
      console.log('iam calling')
      if(window.ethereum){
      var web3 = new Web3(window.ethereum);
      const getaccounts = await web3.eth.requestAccounts();
      web3.eth.net.getId().then(async(res) => {
        console.log(res,config.networkVersion)
        if (res.toString() === config.networkVersion) {
          toastAlert('success', "Wallet connected 🦊 ", 'wallet');
          setaccount(getaccounts[0]);
          localStorage.setItem('walltype','metamask');
          localStorage.setItem('account', getaccounts[0]);
          window.location.reload(false)
        } else {
          toastAlert('error', "Connect BSC Mainnet", 'wallet');
            try {
              await web3.currentProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: Web3.utils.toHex(parseInt(config.networkVersion)) }],
              });
          toastAlert('success', "Wallet connected 🦊 ", 'wallet');
          setaccount(getaccounts[0]);
          localStorage.setItem('walltype','metamask');
          localStorage.setItem('account', getaccounts[0]);
          window.location.reload(false);
            } catch (switchError) {
              console.log("some error on switching",switchError);
            }
        }
      });
    }else{
      toastAlert('error', "please add metamask Extension", 'wallet');
      return;
    }
    }
    async function connectTrustwallet() {
      var web3 = new Web3(config.liverpcUrls);
      var provider1 = new WalletConnectProvider({
        rpc: {
          [config.livechainid]: config.liverpcUrls
        },
        chainId: config.livechainid
      });
      provider1.on("connect", () => {
        setTimeout(() => window.$('#connect_wallet_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(false), 1200);
      });
      await provider1.enable();
      web3 = new Web3(provider1);
      web3.eth.net.getId().then(async res => {
        if (res == 56) {
          var getaccounts = await web3.eth.getAccounts();
          localStorage.setItem('walltype', "trust");
          localStorage.setItem('account', getaccounts[0]);
          toastAlert('success', "Wallet connected 🛡", 'wallet');
        } else {
          toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
          setaccount("")
          return false;
        }
      });
    }

    if(popupStatus)
        setTimeout( () => { closePopup(); }, 10000 ); // close popup after 8 seconds

    return(
        <div className="modal fade primary_modal" id="connect_wallet_modal" tabindex="-1" aria-labelledby="connect_walletModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">Connect Wallet</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button" onClick={connectMetamask}>
                      <img src={require("../assets/images/metamask.png")} height="28" width="28" className="img-fluid" /> <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button" onClick={connectTrustwallet} >
                      <img src={require("../assets/images/walletconnect.png")} height="28" width="28" className="img-fluid" /> <span>WalletConnect</span>
                    </button>
                  </a>
                  {/* <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img src={require("../assets/images/torus.png")} height="28" width="28" className="img-fluid" /> <span>Torus</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img src={require("../assets/images/portis.png")} height="28" width="28" className="img-fluid" /> <span>Portis</span>
                    </button>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Wallet_Popup;