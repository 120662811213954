import React from 'react';
import config from '../config/config'

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className='footer_panel'>
          <div className='footer_panel_left'>
            <p>Smart-contract address: </p>
            <a href={config.bscscan} target="_blank">{config.contractaddress}</a>            
            <p>Copy right © {new Date().getFullYear()} Cryptopump , All rights Reserved</p>
          </div>
          <div className='footer_panel_right'>
            <div className='token_address'>
              <p>Token address (CPUMP): </p>
              <a href={`https://bscscan.com/address/${config.tokenaddress}#code`} target="_blank">{config.tokenaddress}</a>
            </div>
            <ul className='footer_social_links'>
              <li><a href="https://www.facebook.com/cryptopump99/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="https://twitter.com/cryptopumpclub8?t=jTTG3wWOpMeKo0APkkp0og&s=09" target="_blank"><i className="fab fa-twitter"></i></a></li>
              {/* <li><a href="javascript:void(0)" target="_blank"><i className="fab fa-linkedin-in"></i></a></li> */}
              <li><a href="https://t.me/+fVlIq2p1GupiNjBl" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
              <li><a href="https://linktr.ee/cryptopumpclub" target="_blank"><img src={require("../assets/images/linktree_icon.png")} alt="icon" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );  
}