import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer.js';
import Navbar from '../components/Navbar.js';
import ReactTooltip from 'react-tooltip';
import Web3 from 'web3';
import { getprovider } from '../actions/walletconnect';
import cryptopumbABI from '../ABI/cryptopump.json';
import config from '../config/config';
import { toastAlert } from '../helper/toastAlert.js';
import ReactLoading from 'react-loading';
import Loader from '../components/Loader.js';
import { register, checkregister } from '../actions/users';
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, getFormatMulticall } from '../actions/comman';
import Wallet_Popup from '../components/Wallet_Popup.js';
import isEmpty from '../helper/isEmpty';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


let initialvalidation = {
  referid: '',
}
export default function Register(props) {
  let [referID, setreferID] = useState();
  let [limitrefer, setlimitrefer] = useState();
  let [validation, setvalidation] = useState(false);
  let [loader, setloader] = useState(false);
  let [user, setuser] = useState();
  let [registerfee, setregisterfee] = useState();
  let [PopupStatus, setPopupStatus] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id, "idddddddd")
  // var address = localStorage.getItem("account");



  useEffect(() => {
    init();
  }, [])

  const init = (async () => {
    if (!window.web3 && id) {
      let rid = parseInt(id)
      setreferID(rid)
    }
    var { provider, address } = await getprovider();
    console.log(address, config.contractaddress, provider, 'proverssssss');
    var CryptopumpContract = [
      {
        reference: "dashboard",
        contractAddress: config.contractaddress,
        abi: cryptopumbABI,
        calls: [
          {
            reference: "users",
            methodName: "users",
            methodParameters: [address],
          },
          {
            reference: "totaluser",
            methodName: "currentId"
          },
          {
            reference: "level1",
            methodName: "LEVEL_PRICE",
            methodParameters: [1],
          }
        ]
      }
    ];
    console.log(CryptopumpContract, 'ABI')
    let web3 = new Web3(config.provider);
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: false });
    const dashboardresults = await multicall.call(CryptopumpContract);
    const User = await getFormatMulticall(dashboardresults, "dashboard", 0);
    const limitreferID = await getFormatMulticall(dashboardresults, "dashboard", 1);
    const registerprice = await getFormatMulticall(dashboardresults, "dashboard", 2);

    setregisterfee(parseInt(registerprice.hex));
    console.log(parseInt(limitreferID.hex), "limitreferID", User[0], "User", parseInt(registerprice.hex) / 1e18, "register");
    setlimitrefer(parseInt(limitreferID.hex))
    setuser(User[0]);
    if (id) {

      let limit = parseInt(limitreferID.hex);
      let rid = parseInt(id)
      console.log(limit, 'limit', rid, 'rid')
      if (limit >= rid) {
        setreferID(rid);
      } else {
        setreferID(rid);
        setvalidation(true)
      }
    }
  })
  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
      console.log('valid');
    } else {
      setreferID("");
    }
  };


  const handlechange = ((e) => {
    console.log(e.target.value, "values")
    if (limitrefer >= e.target.value) {
      setreferID(e.target.value);
      setvalidation(false);
    } else {
      setvalidation(true)
    }
  })
  const submit = (async () => {
    try {
        let { provider, address } = await getprovider()
        if (!provider && !address) {
          toastAlert("error", "Please Add Metamask Extension", "wallet")
          return;
        }
        if (!referID) {
          toastAlert("error", "please enter referID", "register")
          return;
        }
        
        let localaddress = localStorage.getItem("account"); 
        console.log(isEmpty(localaddress) && !validation,'condition',localaddress,validation)
        if (!isEmpty(localaddress) && !validation) {
          setloader(true)
          let { provider, address } = await getprovider();
          console.log(provider, "provider");
          let web3 = new Web3(provider);
          var network = await web3.eth.net.getId();
          if (config.livechainid != network) {
            toastAlert('error', "Please connect binance mainnet", "wallet")
            setloader(false)
            return;
          }
          console.log(address, "data");
          let bal = await web3.eth.getBalance(address);
          let val = web3.utils.fromWei(bal);
          // let estimate = await web3.eth.getGasPrice();
          // // if(parseInt(val)>= (parseInt(estimate+registerfee)+1000)){
          // estimate = web3.utils.fromWei(estimate);
          // console.log(val,"estimateeee",estimate+registerfee)

          let contract = new web3.eth.Contract(cryptopumbABI, config.contractaddress);
          console.log("reguser", referID, registerfee, address);

          var estimategas = await contract.methods.regUser(referID).estimateGas({ value: registerfee, from: address }).catch((err) => {
            console.log(err, 'err')
            var message = (err && err.message) ? err.message : ""
            var position = message.search("insufficient funds for gas");
            if (position > 0) {
              toastAlert('error', "Insuffucient funds for gas fee", 'register');
            } else {
              toastAlert('error', "Oops something went wrong.!", 'register');
            }
            setloader(false);
            return;
          })
          estimategas = estimategas + 1000;
          estimategas = estimategas / 10 ** 8;

          var userbalance = parseFloat(val);
          console.log(estimategas, 'estimategasestimategas', userbalance)
          if (userbalance >= estimategas) {
            let res = await contract.methods.regUser(referID).send({ value: registerfee, from: address }).catch((err) => {
              console.log(err, 'err')
              setloader(false);
              toastAlert('error', "register failed", 'register');
              return;
            })
            console.log(res, "register");
            console.log(res.events.lostMoneyForLevelEvent, 'eventarray')
            let moneyforlevel = res.events.getMoneyForLevelEvent ? res.events.getMoneyForLevelEvent.returnValues : {};
            let reqdata =
            {
              address: address,
              referId: referID,
              passedup: res.events.lostMoneyForLevelEvent ? res.events.lostMoneyForLevelEvent.returnValues : '',
              userId: moneyforlevel.UserId,
              referrerAddress: moneyforlevel.ReferrerAddress,
              levelPrice: parseInt(moneyforlevel.LevelPrice) / 1e18,
              txnhash: res.transactionHash,
              adminfee: parseInt(moneyforlevel.Adminfee) / 1e18,
            }
            console.log(reqdata, 'reqdata')
            let { success, message } = register(reqdata)
            toastAlert('success', "register successfully", 'register');
            setloader(false);
            navigate('/dashboard')
          } else {
            toastAlert('warning', "You don't have a balance", 'register');
            setloader(false);
            return;
          }
        } else {
          toastAlert('warning', "please connect wallet", 'wallet');
          setloader(false);
        }
    } catch (err) {
      console.log(err)
      toastAlert('warning', "regsiter failed",'register');
    }
  })

  const connect = () => {
    console.log(user, 'useruser')
    if (user) {
      localStorage.setItem('showpopup', "yes")
      navigate('/dashboard')
    } else {
      toastAlert('warning', "Please Register")
    }
  }
  return (
    <div className='auth_wrap'>
      <ScrollToTopOnMount />
      <Navbar />
      <div className='inner_wrapper'>
        <div className='container'>
          <div className='row dashboard_top_wrap'>
            <div className='col-lg-6 m-auto'>
              <div className='primary_box dash_box'>
                <div className='title_flex justify-content-center'>
                  <h2 className='inner_title'>Register</h2>
                </div>
                <div className='dashbox_middle_top'>
                  <div className='dashbox_middle_bottom'>
                    <div className='inp_grp'>
                      <p>Referral ID</p>
                      <input type="text" onChange={handlechange} value={referID} className="form-control primary_inp" />
                    </div>
                    {validation && <span>please enter valid referID</span>}

                  </div>
                  {loader &&
                    <div className="loader_section_"><ReactLoading type={"bars"} color={"#ffffff"} className="loading" /></div>}
                </div>
                <div className="onclick mt-3">
                  {user ? <button type='button' className='primary_btn btnHomeJoin' disabled="true" >submit</button> : <button type='button' className='primary_btn btnHomeJoin' disabled={loader ? true : false} onClick={submit}>submit</button>}
                </div>
                <div className='text-center mt-3'>
                  <a href="javascript:void(0)" onClick={connect}>Registered Already? Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* Connect Wallet Modal */}
      <Wallet_Popup setPopupStatus={setPopupStatus} PopupStatus={setPopupStatus} />
    </div>
  );
}