import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import ReactTooltip from "react-tooltip";
import Web3 from "web3";
import { getprovider } from "../actions/walletconnect";
import cryptopumbABI from "../ABI/cryptopump.json";
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert.js";
import ReactLoading from "react-loading";
import Loader from "../components/Loader.js";
import { register, checkregister } from "../actions/users";
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, getFormatMulticall } from "../actions/comman";
import bnb_usdABI from "../ABI/bnb_usd.json";
import { allLevel } from "../actions/users";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

let initialvalidation = {
  referid: "",
};
export default function Login(props) {
  let [limitrefer, setlimitrefer] = useState();
  let [user, setuser] = useState();
  let [registerfee, setregisterfee] = useState();
  let [usdPirce, setusdPrice] = useState();
  let [level1price, setlevel1price] = useState();
  let [level2price, setlevel2price] = useState();
  let [level3price, setlevel3price] = useState();
  let [level4price, setlevel4price] = useState();
  let [level5price, setlevel5price] = useState();
  let [totalbnb, settotalbnb] = useState();
  let [totaluser, settotaluser] = useState();
  let [userdetails, setuserdetails] = useState();
  const [allLevelUser, setAllLevelUser] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  console.log(typeof id, "idddddddd");
  let address = localStorage.getItem("account");
  var CryptopumpContract = [
    {
      reference: "dashboard",
      contractAddress: config.contractaddress,
      abi: cryptopumbABI,
      calls: [
        {
          reference: "users",
          methodName: "users",
          methodParameters: [address],
        },
        {
          reference: "totaluser",
          methodName: "currentId",
        },
        {
          reference: "level1",
          methodName: "LEVEL_PRICE",
          methodParameters: [1],
        },
      ],
    },
  ];

  const getData = async () => {
    const { status, message, result } = await allLevel();
    setAllLevelUser(result)
  };

  useEffect(() => {
    getData();
  }, []);

  const connect = async () => {
    let { provider, address } = await getprovider();
    let web3 = new Web3(provider);
    const multicall = new Multicall({
      web3Instance: web3,
      tryAggregate: false,
    });
    const dashboardresults = await multicall.call(CryptopumpContract);
    let web3js = new Web3("https://bsc-dataseed.binance.org/");
    let USD_pricecontract = new web3js.eth.Contract(bnb_usdABI, config.bnb_usd);
    let usdpriceval = await USD_pricecontract.methods.latestAnswer().call();
    console.log(usdpriceval, "pricessssss");
    let convert = usdpriceval / 1e8;
    setusdPrice(convert);
    const userinfo = await getFormatMulticall1(dashboardresults, "dashboard", 0);
    const level1info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      1
    );
    const level2info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      2
    );
    const level3info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      3
    );
    const level4info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      4
    );
    const level5info = await getFormatMulticall(
      dashboardresults,
      "dashboard",
      5
    );
    const gettotal = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      6
    );
    const gettotaluser = await getFormatMulticall1(
      dashboardresults,
      "dashboard",
      7
    );
    console.log(gettotal, "gettotal");
    setlevel1price(level1info);
    setlevel2price(level2info);
    setlevel3price(level3info);
    setlevel4price(level4info);
    setlevel5price(level5info);
    settotalbnb(gettotal);
    settotaluser(gettotaluser);
    setuserdetails(userinfo);
    console.log(userinfo, "userinfo");
    console.log(gettotal, "totallllllll");
    console.log(userinfo[0], parseInt(userinfo[1].hex), parseInt(userinfo[2].hex), parseInt(userinfo[3].hex), parseInt(userinfo[4].hex), "dashboardinforsss");
    if (userinfo[0] == true) {
      navigate('/dashboard');
      localStorage.setItem('value', userinfo[0])
      localStorage.setItem('showpopup', "yes")
    } else {
      navigate('/register');
    }
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <Loader />
      <div className="inner_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title_flex">
                <h2 className="inner_title">Login</h2>
              </div>
              <div className="row dashboard_top_wrap">
                <div className="col-lg-3"></div>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div className="dashbox_middle_bottom">
                          </div>
                        </div>
                        <div className="onclick">
                          <button
                            type="button"
                            className="submit_btn2"
                            onClick={connect}
                          >
                            Connect
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
