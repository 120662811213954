import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import $ from "jquery";
import Web3 from 'web3';
import { useNavigate } from "react-router-dom";
import { getprovider } from '../actions/walletconnect';
import { checkregister } from '../actions/users';
import cryptopumbABI from '../ABI/cryptopump.json'
import tokenABI from '../ABI/tokenABI.json'
import config from '../config/config'
import WalletConnectProvider from "@walletconnect/web3-provider";
// import Wallet_Popup from './Wallet_Popup';

export default function Navbar(props) {
  var navigate = useNavigate();

  const [selected, setSelected] = useState('GB');
  let [address, setaddress] = useState();
  let [PopupStatus, setPopupStatus] = useState();
  let [balance, setbalance] = useState();
  let [tokenbalance, settokenbalance] = useState();

  let [user, setuser] = useState();
  useEffect(() => {
    loadScript()
  }, []);

  useEffect(() => {
    if (localStorage.getItem("account")) {
      console.log('i am working')
      init();
      // loadTrustwallet()
    }
  }, []);

  const init = (async () => {
    let { provider, address } = await getprovider();
    console.log(provider, "provider");
    let web3 = new Web3(provider);
    console.log(address, "data");
    let bal = await web3.eth.getBalance(address);
    bal = web3.utils.fromWei(bal);
    let contract = new web3.eth.Contract(cryptopumbABI, config.contractaddress);
    setaddress(address);
    setbalance(bal);
    let tokencontract = new web3.eth.Contract(tokenABI, config.tokenaddress);
    let tbal = await tokencontract.methods.balanceOf(address).call();
    tbal = web3.utils.fromWei(tbal);
    settokenbalance(tbal);
    if (address) {
      let user = await contract.methods.users(address).call()
      if (user) {
        console.log(user[0], "user123")
        setuser(user[0]);
      }
    }
  })

  async function loadTrustwallet() {

    try {
      if (localStorage.getItem("walltype") == "trust") {
        const provider = new WalletConnectProvider({
          rpc: {
            [config.livechainid]: config.liverpcUrls,
          },
          chainId: config.livechainid,
        });

        provider.on("connect", () => {

        });

        provider.on("disconnect", async (code, reason) => {
          if (localStorage.getItem("walltype") == "trust") {
            const provider = new WalletConnectProvider({
              rpc: {
                [config.livechainid]: config.liverpcUrls,
              },
            });
            await provider.disconnect();
            await provider.enable();
          }
        });

      }
    } catch (err) {

    }
  }

  function loadScript() {
    // <a href="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></a>
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }

  const wallet = (() => {
    console.log("iam calling")
    window.$("#connect_wallet_modal").show();
    alert('hi')
  })

  async function disconnect() {
    loadTrustwallet()
    localStorage.clear();
    navigate("/");
  }

  const connect = () => {
    console.log(user, 'useruser')
    localStorage.setItem('showpopup', "yes")
    navigate("/dashboard")
  }
  console.log(address, "address")
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-light main_navbar">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={require("../assets/images/logo.png")} className='img-fluid' alt='logo' />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className='ml-auto navbar_right'>
            {/* <button className='wallet_btn'><img src={require("../assets/images/wallet.png")} className='img-fluid' alt='Logout' onClick={wallet} /></button> */}
<ul>
            <li>
            <div className="dropdown">
              <span className="listItem" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false">
                {localStorage.getItem("account") ? <button className="logout_btn dropdown-toggle" ><p className="address_btn">{localStorage.getItem("account")}</p></button> : <button className='primary_btn' data-toggle="modal" data-target="#connect_wallet_modal">Connect Wallet</button>}
              </span>
              {localStorage.getItem("account") && (
                <div className="dropdown-menu walletDropMain" aria-labelledby="navbarDropdown">
                  <p><label>BNB Balance</label><span>{balance && <p>{balance} BNB</p>}</span></p>
                  <p><label>CPUMP Balance</label><span>{tokenbalance && <p>{tokenbalance} CPUMP</p>}</span></p>
                  {user && <Link to={"/dashboard"} onClick={connect}>Dashboard</Link>}
                  <button className='logout_btn w-100 d-block' onClick={() => disconnect()}><img src={require("../assets/images/logout_btn.png")} className='img-fluid' alt='Logout' /> Disconnect</button>
                </div>
              )}
            </div>
            </li>
            {/* <button className='logout_btn'><img src={require("../assets/images/logout_btn.png")} className='img-fluid' alt='Logout' /> Logout</button>      */}
            {/* <ReactFlagsSelect selected={selected} onSelect={code => setSelected(code)} className="lan_select" countries={["GB", "FR","DE","IN","IT"]} customLabels={{"GB": "English","FR": "French","DE": "German","IN": "Tamil","IT": "Italian"}} /> */}

            {/* <div id="google_translate_element"> */}
            {/* <div>
              <ul id="root_gt"></ul>
            </div> */}

            {/* </div> */}
            {localStorage.getItem("account") &&
            <li>
              <Link to={"/create-coin"}>Add Coin</Link>
            </li>
          }
          <li>
            <Link to={"/coin-list"}>Votings</Link>
          </li>
          </ul>
          </div>
          
        </div>


      </div>
      {/* <Wallet_Popup setPopupStatus={setPopupStatus} PopupStatus={setPopupStatus}/> */}
    </nav>
  );
}