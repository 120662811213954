// import lib
import isEmpty from "../../helper/isEmpty";
import Web3 from "web3";

const validation = (value) => {
  let errors = {};
  if (isEmpty(value.logo && value.logo.file < 0)) {
    errors.logo = "Please select logo";
  }

  function returnFileSize(number) {
      return (number / 1048576).toFixed(1);
    }

  if(returnFileSize(value.logo.size) > 5) {
      errors.logo = "Please choose file below 5 MB"
  }

  if (isEmpty(value.coin_name)) {
    errors.coin_name = "Please enter your coin name";
  }

  if (isEmpty(value.coin_symbol)) {
    errors.coin_symbol = "Please enter your coin symbol";
  }
  if (isEmpty(value.network)) {
    errors.network = "Please select network";
  }
  if (isEmpty(value.platform)) {
    errors.platform = "Please select platform";
  }
  if (isEmpty(value.presale)) {
    errors.presale = "Please select presale";
  }
  if (isEmpty(value.contract_address)) {
    errors.contract_address = "Please enter your contract address";
  } else if (!Web3.utils.checkAddressChecksum(value.contract_address)) {
    errors.contract_address = "Please enter valid contract address";
  }
  if (isEmpty(value.category)) {
    errors.category = "Please select category";
  }
  if (isEmpty(value.coin_description)) {
    errors.coin_description = "Please enter your coin description";
  }
  if (isEmpty(value.website_link)) {
    errors.website_link = "Please enter your website link";
  }
  if (isEmpty(value.telegram_contact)) {
    errors.telegram_contact = "Please enter your telegram contact";
  }

  return errors;
};

export default validation;
