import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import ReactTooltip from "react-tooltip";
import copy from "copy-to-clipboard";
import config from "../config/config";
import tokenABI from "../ABI/tokenABI.json";
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, getFormatMulticall } from "../actions/comman";
import { buynewLevel, allLevel, transactionHistory } from "../actions/users";
import { createvoting } from "../actions/users";
import { getprovider } from "../actions/walletconnect.js";
import { toastAlert } from "../helper/toastAlert.js";
import Web3 from "web3";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import bnb_usdABI from "../ABI/bnb_usd.json";
import ReactLoading from "react-loading";
import { InlineShareButtons } from "sharethis-reactjs";
import $ from "jquery";
import { referalTreeLevelNext } from "../pages/tree";
import DataTable from 'react-data-table-component';
import moment from "moment"


// Trade History Table
const data = [
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "500", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "10000", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "100000", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "378520", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "685123", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "247963", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "7412", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "789456", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
];



var zeroAddr = "0x0000000000000000000000000000000000000000";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var initialData = {
  "name": "",
  "symbol": "",
  "address": "",
  "logoURI": "",
  "amount": "",
  "showamount": "",
  "value": ""
}

var priceinterval;

export default function Dashboard(props) {
  let [loader, setloader] = useState();
  const [fromValue, setfromValue] = useState(initialData);
  const [toValue, settoValue] = useState(initialData);
  const [current, setcurrent] = useState("");
  const [swapcurrent, setswapcurrent] = useState("");
  const [frombalance, setfrombalance] = useState({ "balance": "Loading", "balanceOf": 0 });
  const [tobalance, settobalance] = useState({ "balance": 0, "balanceOf": 0 });
  const [showapprove, setshowapprove] = useState(false);
  const [approvebtn, setapprovebtn] = useState(false);
  const [swapbtn, setswapbtn] = useState(false);

  const [insufficienterror, setinsufficienterror] = useState(false);
  const [Insufficienttoken, setInsufficienttoken] = useState("");

  const [checkallowance, setcheckallowance] = useState(false);
  const [showswap, setshowswap] = useState(false);

  const [slippageValue, setslippageValue] = useState(0.1);
  const [transdeadline, settransdeadline] = useState(5);
  const [pairisExits, setpairisExits] = useState("");

  const [minimumReceived, setminimumReceived] = useState(0);
  const [priceImpact, setpriceImpact] = useState(0);
  const [liquidityFee, setliquidityFee] = useState(0);
  const [currentRouter, setcurrentRouter] = useState("");
  const [swaploading, setswaploading] = useState(false);

  const [tokenList, settokenList] = useState([]);
  const [swapList, setswapList] = useState([]);
  const [tokenprice, settokenprice] = useState(0);
  const [loadhistory, setloadhistory] = useState(false);

  const [showChart, setshowChart] = useState(true);
  const [receivedamt, setreceivedamt] = useState(0);
  const [enterValue, setenterValue] = useState(0);
  const [perRate, setperRate] = useState(0);
  const [swapService, setswapService] = useState(0);
  const [fromOriginal, setfromOriginal] = useState(0);
  const [priceDetail, setpriceDetail] = useState({ usdPrice: 0, bnbPrice: 0 });
  const [PairData, setPairData] = useState({});
  const [isLoadPair, setisLoadPair] = useState(false);
  const [authValue, setauthValue] = useState("");
  const [isChange, setisChange] = useState(false);
  const [adminAddr, setadminAddr] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [title, settitle] = useState("");
  const [explanation, setexplanation] = React.useState("");
  const [values, setValues] = useState({ val: [] });
  const [Accounts, Set_Accounts] = useState("");
  const [votingpower, set_votingpower] = useState(0);
  const [process, setprocess] = useState(false);
  const [propsalval, setpropsalval] = useState("core");
  const [tokenbalance, settokenbalance] = React.useState({ balance: 0, balanceOf: 0 });
  const [tokenpower, settokenpower] = React.useState(0);

  useEffect(() => {
    callclick();
    votepowercall()
  }, []);

  async function AfterWalletConnected() {
  }

  async function votepowercall() {
    let { provider, address } = await getprovider();
    if (address) {
      let web3 = new Web3(provider);
      var currAddress = address;
      let bal = await web3.eth.getBalance(address);
      let balance = web3.utils.fromWei(bal);
      let CryptopumpContract = new web3.eth.Contract(
        tokenABI,
        config.tokenaddress
      );
      var balanceOf = await CryptopumpContract.methods.balanceOf(address).call();
      balanceOf = web3.utils.fromWei(balanceOf);
      settokenbalance({ balance: balance, balanceOf: balanceOf });
      var token_power = parseFloat(config.tokenpower) * parseFloat(balance)
      settokenpower(token_power)
    }
  }

  async function callclick() {
    setValues({ val: [...values.val, ''] })
  }

  const handleChangetitle = (e) => {
    settitle(e.target.value)
  }

  const onEditorChange = (evt) => {
    var description_text = evt.editor.getData();
    setexplanation(description_text)
  }
  function handleChangedescription(event) {

    setexplanation(event.target.value)
  }

  function handleChange(event) {
    let vals = [...values.val];
    vals[this] = event.target.value;
    setValues({ val: vals });
  }

  function removeClick(i) {
    let vals = [...values.val];
    vals.splice(i, 1);
    console.log(vals)
    setValues({ val: vals });
  }

  const addClick = () => {
    setValues({ val: [...values.val, ''] })
  }


  function createInputs() {
    return values.val.map((el, i) =>
      <div className="form-group" key={i}>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{parseInt(i) + 1}</span>
          </div>
          <input type="text" class="primary_inp" onChange={handleChange.bind(i)} value={el} />
          <div class="input-group-append">
            <span class="input-group-text" onClick={() => removeClick(i)}><i class="fas fa-times"></i></span>
          </div>
        </div>
      </div>
    );
  }

  function choiceValidation() {
    var isValid = true;
    var cow = 0;
    if (values.val.length > 0) {
      for (let i = 0; i < values.val.length; i++) {
        if (values.val[i] != "") {
          return true;
          break;
        } else {
          cow = parseInt(cow) + parseInt(1)
        }
        if (parseInt(cow) == values.val.length) {
          return false;
        }
      }
    } else {
      toastAlert('error', "Enter Choice for Voting", 'error');
      return false
    }
  }

  async function submit_publish() {
    let { provider, address } = await getprovider();
    if (address) {
      let web3 = new Web3(provider);
      let TokenContract = new web3.eth.Contract(
        tokenABI,
        config.tokenaddress
      );

      let balanceOf = await TokenContract.methods.balanceOf(address).call();
      balanceOf = web3.utils.fromWei(balanceOf);
      var myvotepower = parseFloat(tokenpower)
      if (myvotepower <= parseFloat(balanceOf)) {
        var startdate = moment(startDate).startOf('day').format()
        var enddate = moment(endDate).startOf('day').format()
        var starttime = moment(startTime).diff(moment().startOf('day'), 'seconds');
        var endtime = moment(endTime).diff(moment().startOf('day'), 'seconds');
        var finalstartdate = moment(startdate).add(starttime, 'seconds').format();
        var finalenddate = moment(enddate).add(endtime, 'seconds').format();
        setprocess(true)
        if (address && address != "" && address != null) {
          var currAddress = address
          if (title != "" && title != undefined && title != null) {
            if (explanation != "" && explanation != undefined && explanation != null) {
              if (choiceValidation()) {
                var a = moment(finalstartdate);
                var b = moment(finalenddate);
                var now = moment();
                var finish = b.diff(a)
                if ((moment(finalenddate)).isAfter(now) == true && finish != "" && parseInt(finish) > 0) {
                  var createdata = {
                    "question": title,
                    "explanation": explanation,
                    "choices": values,
                    "startdate": finalstartdate,
                    "enddate": finalenddate,
                    "starttime": starttime,
                    "endtime": endtime,
                    "address": currAddress,
                    "propsal": propsalval
                  }

                  var restul = await createvoting(createdata);
                  toastAlert('success', "Published Successfully", 'error');
                  window.location.reload()
                } else {
                  setprocess(false)
                  toastAlert('error', "Choose Valid End Date", 'error');
                }
              } else {
                setprocess(false)
                toastAlert('error', "Enter Valid Choice", 'error');
              }
            } else {
              setprocess(false)
              toastAlert('error', "Enter Valid Description", 'error');
            }
          } else {
            setprocess(false)
            toastAlert('error', "Enter Valid Details", 'error');
          }
        } else {
          setprocess(false)
          toastAlert('error', "Connect BSC Testnet", 'excerciseerr');
        }
      } else {
        setprocess(false)
        toastAlert('error', "You must have atleast " + config.createpower + " voting power to make a new proposal ", 'excerciseerr');
      }
    } else {
      setprocess(false)
      toastAlert('error', "Connect BSC Testnet", 'excerciseerr');
    }
  }
  function propasalType(event) {
    setpropsalval(event.target.value)
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <ReactTooltip />
      <div className="inner_wrapper">
        <div className="inner_pageheader">
          <div className="inner_content_wrapper proposal_content_panel">
            <div className="container">
              <div className="primary_box dash_box make_proposal_panel">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="proposal_panel_left">
                      <form className="make_proposal_form">
                        <h2>Make a Proposal</h2>
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label for="title">Title</label>
                            <input type="text" className="form-control primary_inp" id="title" placeholder="Enter your title here" value={title} onChange={handleChangetitle} />
                          </div>
                          <div className="form-group col-md-12">
                            <label for="title">Proposals</label>
                            <select className="primary_inp form-control" onChange={propasalType}>
                              <option value={"core"}>Core</option>
                              <option value={"community"}>Community</option>
                            </select>
                          </div>
                          <div className="form-group col-md-12">
                            <label for="inputEmail4">Content </label>
                            <textarea className="form-control primary_inp" rows={3} placeholder="Enter your proposal here" value={explanation} onChange={handleChangedescription}></textarea>
                            {/* <div className="desc_editor"><CKEditor id="explanation" data="<p></p>" onChange={onEditorChange} /></div> */}
                          </div>
                          <div className="form-group col-md-12">
                            <label for="choice1">Choices</label>
                            <div className="choice_inp">
                              {createInputs()}
                            </div>
                            {/*<input type="text" className="form-control primary_inp" placeholder="Input choice text" id="choice1" placeholder="Input choice text" />*/}
                          </div>
                        </div>
                        <button type="button" className="primary_btn" onMouseEnter={() => votepowercall()} onClick={addClick}><i className="bi bi-plus"></i>Add Choice</button>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="proposal_panel_right">
                      <form className="make_proposal_form">
                        <h2>Actions</h2>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="sdate">Start Date</label>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} selectsStart startDate={startDate} endDate={endDate} className="form-control primary_inp text-center" id="sdate" />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="edate">End Date </label>
                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} className="form-control primary_inp text-center" id="edate" />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="stime">Start Time</label>
                            <DatePicker selected={startTime} onChange={(date) => setStartTime(date)} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" className="form-control primary_inp text-center" id="stime" />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="etime">End Time</label>
                            <DatePicker selected={endTime} onChange={(date) => setEndTime(date)} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" className="form-control primary_inp text-center" id="etime" />
                          </div>
                        </div>
                        <div className="proposal_creator_details">
                          <div>
                            <label>Creator:</label>
                            {localStorage.getItem("account") && localStorage.getItem("account") != null && localStorage.getItem("account") != "" ?
                              <label>{(localStorage.getItem("account")).substring(0, 4) + '.....' + (localStorage.getItem("account")).substring((localStorage.getItem("account")).length - 4, (localStorage.getItem("account")).length)}</label>
                              :
                              <label>---</label>
                            }
                          </div>
                          {/*<div>
                                <label>Snapshot:</label>
                                <label>123456789</label>
                              </div>*/}
                        </div>
                        <div className="check_voting_power_div">
                          <p>{"You need at least " + config.createpower + " voting power to publish a proposal"}</p>
                        </div>
                        <div className="proposal_action_grp_btns">
                          <button type="button" className="primary_btn" data-toggle="modal" data-target="#voting_power_modal" onMouseEnter={() => votepowercall()}>Check Voting Power</button>
                          {localStorage.getItem("account") && localStorage.getItem("account") != null && localStorage.getItem("account") != "" ?
                            <button type="button" className="primary_btn" onMouseEnter={() => votepowercall()} onClick={submit_publish}>Publish</button>
                            :
                            <button className="primary_btn" disabled>Publish</button>
                          }
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Voting Power Modal */}
      <div className="modal fade primary_modal" id="voting_power_modal" tabindex="-1" aria-labelledby="voting_power_modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="voting_power_modalLabel">Voting Power</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="voting_power_overview_top">
                <p>Your voting power is detemined by the amount of Cryptopump you held at the block detailed below. Cryptopump held in other places does not contribute to your voting power.</p>
                <h4>Overview</h4>
                <div className="voting_power_overview_div">
                  <h5>Your Voting Power</h5>
                  <h5>{(parseFloat(tokenpower)).toFixed()}</h5>
                </div>
              </div>
              <div className="voting_power_overview_bottom">
                <div className="voting_power_overview_left">
                  <div className="voting_power_overview_detail">
                    <div>
                      <label>Wallet (Cryptopump)</label>
                      <label>{tokenbalance.balanceOf}</label>
                    </div>
                    {/*   <div>
                      <label>Referred Wallets</label>
                      <label>{parseFloat(referralpower).toFixed()}</label>
                    </div>
                    <div>
                      <label>Pool</label>
                      <label>1213.03</label>
                    </div>
                    <div>
                      <label>Farms</label>
                      <label>1.023</label>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="primary_btn w_sm_100_btn ml-0" data-dismiss="modal" aria-label="Close">Close</button>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader_section_">
          <ReactLoading type={"bars"} color={"#ffffff"} className="loading" />
        </div>
      )}
      <Footer />
    </div>
  );
}
