import react, { useEffect, useState, useMemo, Fragment } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader"
import ReactTooltip from "react-tooltip";
import Loading from 'react-loading';
import DataTable from 'react-data-table-component';
import Moment from 'moment'
import config from '../../config/config'
import {getcoinlist, addcoin, addcoinvote} from '../../actions/coin';
import { getprovider } from "../../actions/walletconnect.js";
import Web3 from "web3";
import tokenABI from "../../ABI/tokenABI.json";
import { toastAlert } from "../../helper/toastAlert.js";

const data = [
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "500", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "10000", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "100000", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "378520", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "685123", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "247963", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "7412", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "789456", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
    { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
];


export default function Coinlist(props) {
    let [loader, setloader] = useState(false);
    let [Coinlist, setCoinlist] = useState([]);
    const [tokenbalance, settokenbalance] = useState({ balance: 0, balanceOf: 0 });
    const [tokenpower, settokenpower] = useState(0);
    const [token_balance,setToken_Balance] = useState(0)

    useEffect(()=>{
        coinslist();
        votepowercall();
    },[])

    const coinslist= async ()=>{
        let coinlist = await getcoinlist();
        console.log(coinlist,'coinlist')
        setCoinlist(coinlist.data);
    }

    // Scroll to Top
    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
        return null;
    }
    var netbalance;
    async function votepowercall() {
        let { provider, address } = await getprovider();
        if (address) {
          let web3 = new Web3(provider);
          var currAddress = address;
          let bal = await web3.eth.getBalance(address);
          let balance = await web3.utils.fromWei(bal);
          netbalance = balance;
          setToken_Balance(balance)
          console.log(balance,'Balllllllllllllllllllllllllllllllllllllllll--------------------------')
          let CryptopumpContract = new web3.eth.Contract(
            tokenABI,
            config.tokenaddress
          );
          var balanceOf = await CryptopumpContract.methods.balanceOf(address).call();
          balanceOf = web3.utils.fromWei(balanceOf);
          settokenbalance({ balance: balance, balanceOf: balanceOf });
          var token_power = parseFloat(config.tokenpower) * parseFloat(balance)
          settokenpower(token_power)
        }
      }

    const VoteSubmit = async (id) => { 
        let { provider, address } = await getprovider();
        if(address) {
            setloader(true)
            var web3 = new Web3(provider);
            var TokenContract = new web3.eth.Contract(
                tokenABI,
                config.tokenaddress
              );

              let balanceOf = await TokenContract.methods.balanceOf(address).call();
              balanceOf = web3.utils.fromWei(balanceOf);
              var myvotepower = parseFloat(tokenpower)
              console.log(myvotepower,'TokenContract------TokenContract');
              if (config.coinvotepower <= parseFloat(balanceOf)) {
                let burntoken = web3.utils.toWei(config.coinvotepower, 'ether');
                var estimategas = await TokenContract.methods.burn(burntoken).estimateGas({from:address})
                .catch((err)=>{
                    var message = (err && err.message) ? err.message : ""
                    var position = message.search("insufficient funds for gas");
                    console.log(position,'meaafeeeeeeee');
                    if (position > 0) {
                      setloader(false)
                      toastAlert('error', "Insuffucient funds for gas fee", 'coin');
                    } else {
                      setloader(false)
                      toastAlert('error', "Oops something went wrong.!", 'coin');
                    }
                    return;
                })

                estimategas = estimategas + 1000;
                estimategas = estimategas / 10 ** 8;

                var userbalance = parseFloat(netbalance);
               console.log(userbalance,estimategas,'||||||||||||||||');
                if (userbalance >= estimategas) {
                    // alert()
                    let burntoken = web3.utils.toWei(config.coinvotepower, 'ether');
                    console.log(burntoken,'_______---------_______----_______-_');
                    try{
                        console.log(Coinlist,'ccccccccccccc')
                        let paytoken = await TokenContract.methods.burn(burntoken).send({from: address });
                        let payload = { user_address : address, votes:1, id:id};
                        let rescoin = await addcoinvote(payload);
                        window.location.reload();
                        // console.log(paytoken,'paytokenpaytokenpaytoken');
                          }catch(err){
                          setloader(false)
                          toastAlert('error', "user Rejected", 'coin');
                          }
                          setloader(false)
                          toastAlert('success', "coin submitted successfully", 'coin');
                } else {
                    setloader(false)
                    toastAlert('error', "you don't have a balance", 'coin');
                }
                
              } else {
                setloader(false)
                  toastAlert('error', "You must have atleast " + config.coinvotepower + " voting power to vote a coin ", 'coin');
              }
        } else {
            setloader(false)
        toastAlert('error', "Connect BSC Testnet", 'coin');
        }

    }

    var navigate = useNavigate();
    let address = localStorage.getItem("account");
    console.log(address, config.contractaddress, "arguments");
    function convert(n) {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    }
    console.log(config.baseurl,'config.baseurl');

    const columns = useMemo(
        () => [
            {
                name: '#',
                // selector: 'serial',
                cell: (row, index) => index + 1,
                //   cell: (row) =>{ return Moment(row.createdAt).format('DD-MM-YYYY')},
                sortable: false,
                width:'50px'
            },
            {
                name: 'Date & Time',
                selector: 'createdAt',
                  cell: (row) =>{ return Moment(row.createdAt).format('DD-MM-YYYY')},
                sortable: false,
            },
            {
                name: 'Coin Logo',
                selector: 'logo',
                sortable: false,
                   cell: row => {
                    console.log(row,'yeryrywytfs');
                      return (
                          <Fragment>
                             <img style={{width:'54px'}} src={`${config.backurl}/images/coins/${row.logo}`} className={'coinImg'} />
                          </Fragment>
                      );
                  }
            },
            {
                name: 'Coin Pair',
                selector: 'coin_symbol',
                sortable: false,
            },
            {
                name: 'Coin Name',
                selector: 'coin_name',
                sortable: false,
            },
            {
                name: 'Chain',
                selector: 'network',
                //   cell: (row) => { return convert(row.price)},
                sortable: false,
            },
            {
                name: 'Category',
                selector: 'category',
                sortable: false,
                //   cell: row => {
                //     let styleName;
                //     if(row.toaddress.toLowerCase() == address){
                //       styleName = 'btn btn-success btn-sm'
                //     }else{
                //       styleName = 'btn btn-danger btn-sm'
                //     }
                //       return (
                //           <Fragment>
                //               <a href={config.bsctrx + row.txnhash}
                //                   className={styleName}
                //                   style={{ marginRight: '5px'}} target="_blank">
                //                   {row.transactionType}
                //               </a>
                //           </Fragment>
                //       );
                //   }
            },
            {
                name: 'Votes',
                selector: 'votes',
                //   cell: (row) => { return convert(row.price)},
                sortable: false,
            },
            {
                name: 'Action',
                selector: 'action',
                //   cell: (row) => { return convert(row.price)},
                sortable: false,
                   cell: row => {
                      return (
                          <Fragment>
                              <a onClick={()=>VoteSubmit(row._id)}
                            //   onMouseEnter={votepowercall}
                              style={{
                                background:'#44b46a',
                                padding:'10px 28px',
                                color:'#ffffff;',
                                cursor:'pointer',
                                textDecoration:'none',
                                fontWeight:'600',
                                borderRadius:'50px'
                                
                                }}>Vote</a>
                          </Fragment>
                      );
                  }
            },
        ], []);

    //     const columns =useMemo(
    // 		() =>  [
    //     {
    //       name: 'Date & Time',
    //       selector: 'createdAt',
    //       cell: (row) =>{ return Moment(row.createdAt).format('DD-MM-YYYY hh:mm')},
    //       sortable: false,
    //     },
    //     {
    //       name: 'Transaction Id',
    //       selector: 'txnhash',
    //       sortable: false,
    //       width: "450px"
    //     },
    //     {
    //       name: 'Level',
    //       selector: 'level',
    //       sortable: false,
    //     },
    //     {
    //       name: 'Amount',
    //       selector: 'price',
    //       cell: (row) => { return convert(row.price)},
    //       sortable: false,
    //     },
    //     {
    //       name: 'status',
    //       selector: 'transactionType',
    //       sortable: false,
    //       cell: row => {
    //         let styleName;
    //         if(row.toaddress.toLowerCase() == address){
    //           styleName = 'btn btn-success btn-sm'
    //         }else{
    //           styleName = 'btn btn-danger btn-sm'
    //         }
    //           return (
    //               <Fragment>
    //                   <a href={config.bsctrx + row.txnhash}
    //                       className={styleName}
    //                       style={{ marginRight: '5px'}} target="_blank">
    //                       {row.transactionType}
    //                   </a>
    //               </Fragment>
    //           );
    //       }
    //   }
    //   ],[]);

    return (
        <div>
            <ScrollToTopOnMount />
            <Navbar />
            <ReactTooltip />
            <div className="inner_wrapper">
                <div className="inner_pageheader">
                    <div className="container">
                        <div className="primary_box dash_box make_proposal_panel mb-5">
                            <div className="inner_banner main-banner">
                                <div className="inner_banner_content">
                                    <div className="inner_title_top">
                                        <h1><span className="pink_light_txt">Voting</span> Details</h1>
                                    </div>
                                    <p className="w-100">Register your coin or token for the cryptopump community, trading every week, the listing fee is 5000 Cpump, each time registration, for each vote requires 100 Cpump each time voting.</p>
                                    <div className="voting_banner_btn">
                                        <button className="primary_btn w_sm_100_btn ml-0">
                                            <Link to="/create-coin">Add Coin</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="inner_pageheader">
                    <div className="container">
                        <div className="primary_box dash_box make_proposal_panel mb-5">
                            <div className="inner_banner main-banner">
                                <div className="inner_banner_content">
                                    <div className="inner_title_top">
                                        <h1><span className="pink_light_txt">Coin</span> list</h1>
                                    </div>
                                    <p className="w-100">Have your say in the future of the social community.</p>
                                    <div className="voting_banner_btn">
                                    <div className="primary_table">
                                        <DataTable columns={columns} data={Coinlist} noHeader pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={[5, 10, 15, 20]} />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {loader && (
                    <div className="loader_section_">
                        <Loader />
                    </div>
                )}
                <Footer />
            </div>
        </div>
    );
}
