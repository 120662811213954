import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import ReactTooltip from "react-tooltip";
import copy from "copy-to-clipboard";
import config from "../config/config";
import tokenABI from "../ABI/tokenABI.json";
import CryptoABI from "../ABI/cryptopump.json";
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, getFormatMulticall } from "../actions/comman";
import { buynewLevel, allLevel, transactionHistory, getcorevotinglist, get_communityvotinglist, getdetailvoting, voteupdate } from "../actions/users";
import { createvoting } from "../actions/users";
import { getprovider } from "../actions/walletconnect.js";
import { toastAlert } from "../helper/toastAlert.js";
import Web3 from "web3";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import bnb_usdABI from "../ABI/bnb_usd.json";
import ReactLoading from "react-loading";
import { InlineShareButtons } from "sharethis-reactjs";
import $ from "jquery";
import { referalTreeLevelNext } from "../pages/tree";
import DataTable from 'react-data-table-component';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import moment from "moment";
import Countdown from "react-countdown";

// Trade History Table
const data = [
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "500", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "10000", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "100000", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "378520", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "685123", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "247963", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "7412", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "789456", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
  { datetime: "2022-07-28 T06:20:33", transID: "0x75Cf28f29266A413eC878CE5C4a1c9682C819586", level: '5', earned: "999999", status: "buylevel" },
];



var zeroAddr = "0x0000000000000000000000000000000000000000";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var initialData = {
  "name": "",
  "symbol": "",
  "address": "",
  "logoURI": "",
  "amount": "",
  "showamount": "",
  "value": ""
}

var priceinterval;

export default function Dashboard(props) {
  let [loader, setloader] = useState();
  const [fromValue, setfromValue] = useState(initialData);
  const [toValue, settoValue] = useState(initialData);
  const [current, setcurrent] = useState("");
  const [swapcurrent, setswapcurrent] = useState("");
  const [frombalance, setfrombalance] = useState({ "balance": "Loading", "balanceOf": 0 });
  const [tobalance, settobalance] = useState({ "balance": 0, "balanceOf": 0 });
  const [showapprove, setshowapprove] = useState(false);
  const [approvebtn, setapprovebtn] = useState(false);
  const [swapbtn, setswapbtn] = useState(false);
  const [tokenbalance, settokenbalance] = React.useState({ balance: 0, balanceOf: 0 });
  const [tokenpower, settokenpower] = React.useState(0);
  const [insufficienterror, setinsufficienterror] = useState(false);
  const [Insufficienttoken, setInsufficienttoken] = useState("");
  const [referralcount, setreferralcount] = useState(0);
  const [checkallowance, setcheckallowance] = useState(false);
  const [showswap, setshowswap] = useState(false);

  const [slippageValue, setslippageValue] = useState(0.1);
  const [transdeadline, settransdeadline] = useState(5);
  const [pairisExits, setpairisExits] = useState("");

  const [minimumReceived, setminimumReceived] = useState(0);
  const [priceImpact, setpriceImpact] = useState(0);
  const [liquidityFee, setliquidityFee] = useState(0);
  const [currentRouter, setcurrentRouter] = useState("");
  const [swaploading, setswaploading] = useState(false);

  const [tokenList, settokenList] = useState([]);
  const [swapList, setswapList] = useState([]);
  const [tokenprice, settokenprice] = useState(0);
  const [loadhistory, setloadhistory] = useState(false);
  const [status, setstatus] = useState("no")
  const [showChart, setshowChart] = useState(true);
  const [receivedamt, setreceivedamt] = useState(0);
  const [enterValue, setenterValue] = useState(0);
  const [perRate, setperRate] = useState(0);
  const [swapService, setswapService] = useState(0);
  const [fromOriginal, setfromOriginal] = useState(0);
  const [priceDetail, setpriceDetail] = useState({ usdPrice: 0, bnbPrice: 0 });

  const [PairData, setPairData] = useState({});
  const [isLoadPair, setisLoadPair] = useState(false);
  const [authValue, setauthValue] = useState("");
  const [isChange, setisChange] = useState(false);
  const [adminAddr, setadminAddr] = useState("");
  const [votedetail, setvotedetail] = useState("");
  const [page, setpage] = useState(10);
  const [process, setprocess] = useState(false);
  const [myopinion, setmyopinion] = useState("");
  const [startdiffer, setstartdiffer] = useState(0);
  const [enddiffer, setenddiffer] = useState(0);
  const [interdiffer, setinterdiffer] = useState(0);
  const [walletaddress, setwalletaddress] = useState()
  const { id } = useParams();

  useEffect(() => {
    setTimeout(() => {
      votepowercall()
    }, 3000);
    getvotedetails()
  }, []);

  async function getvotedetails() {
    var data = {
      "id": id
    }
    var record = await getdetailvoting(data);
    if (record && record.list) {
      setvotedetail(record.list)
      var a = moment(record.list.startdate);
      var b = moment(record.list.enddate);
      var c = moment();
      var startdiff = a.diff(c)
      var enddiff = b.diff(c)
      var interdiff = b.diff(a)
      setstartdiffer(startdiff)
      setenddiffer(enddiff)
      setinterdiffer(interdiff)
    }
  }

  async function loadmore() {
    let pg = parseInt(page) + 10
    setpage(pg)
    getvotedetails();
  }

  async function vote() {
    setprocess(true)
    setloader(true);
    let { provider, address } = await getprovider();
    if (address && address != "") {
      if (myopinion != undefined && myopinion != null && myopinion != "") {
        let web3 = new Web3(provider);
        let TokenContract = new web3.eth.Contract(
          tokenABI,
          config.tokenaddress
        );

        let balanceOf = await TokenContract.methods.balanceOf(address).call();
        balanceOf = web3.utils.fromWei(balanceOf);
        if (balanceOf > 1) {
          let value = web3.utils.toWei('1', 'ether');
          var estimategas = await TokenContract.methods.burn(value).estimateGas({ from: address }).catch((err) => {
            console.log(err, 'err')
            var message = (err && err.message) ? err.message : ""
            var position = message.search("insufficient funds for gas");
            if (position > 0) {
              toastAlert('error', "Insuffucient funds for gas fee", 'vote');
            } else {
              toastAlert('error', "Oops something went wrong.!", 'vote');
            }
            setloader(false);
            return;
          })
          estimategas = estimategas + 1000;
          estimategas = estimategas / 10 ** 8;

          var userbalance = parseFloat(tokenbalance.balance);
          console.log(estimategas, 'estimategasestimategas', userbalance)
          if (userbalance >= estimategas) {
            let value = web3.utils.toWei('1', 'ether');
            try{
            let res = await TokenContract.methods.burn(value).send({ from: address })
          }catch(err){
            console.log(err, 'err')
            setloader(false);
            toastAlert('error', "Voting failed", 'vote');
            return false;
          }
            var myvotepower = (parseFloat(tokenpower)).toFixed()
            if (myvotepower >= parseFloat(config.votingpower)) {
              var currAddress = address
              var data = {
                "address": currAddress,
                "option": myopinion,
                "id": id
              }
              var list = await voteupdate(data)
              toastAlert('success', "Successfully Voted", 'vote');
              getvotedetails()
              setloader(false);
            }
          } else {
            setprocess(false)
            setloader(false);
            toastAlert('error', "You must have atleast " + config.votingpower + " voting power", 'vote');
          }
        } else {
          setprocess(false)
          setloader(false);
          toastAlert('error', "You Don't Have token balance", 'vote');
        }
      } else {
        setprocess(false)
        setloader(false);
        toastAlert('error', "Choose Your Vote", 'vote');
      }
    } else {
      setprocess(false)
      setloader(false);
      toastAlert('error', "Connect BSC Testnet", 'vote');
    }
  }

  async function mychoice(mine) {
    votepowercall();
    setmyopinion(mine)
  }

  async function connectwall() {
    toastAlert('error', "Please Connect Wallet to Proceed Further", 'vote');
  }

  const votepowercallevent = async (e) => {
    let { provider, address } = await getprovider();
    if (address && address != "") {
      var currAddress = address;
      var data = {
        "address": currAddress
      }
      let web3 = new Web3(provider);
      let CryptopumpContract = new web3.eth.Contract(
        tokenABI,
        config.tokenaddress
      );
      var currAddress = address;
      let bal = await web3.eth.getBalance(address);
      let balance = web3.utils.fromWei(bal);
      var balanceOf = await CryptopumpContract.methods.balanceOf(address).call();
      balanceOf = web3.utils.fromWei(balanceOf);
      settokenbalance({ balance: balance, balanceOf: balanceOf });
      var token_power = parseFloat(config.tokenpower) * parseFloat(balanceOf)
      settokenpower(token_power)
      setstatus("yes")
    }
  }

  async function votepowercall() {
    let { provider, address } = await getprovider();
    if (address && address != "") {
      var currAddress = address;
      setwalletaddress(currAddress);
      let web3 = new Web3(provider);
      let CryptopumpContract = new web3.eth.Contract(
        tokenABI,
        config.tokenaddress
      );
      var currAddress = address;
      let bal = await web3.eth.getBalance(address);
      let balance = web3.utils.fromWei(bal);
      var balanceOf = await CryptopumpContract.methods.balanceOf(address).call();
      balanceOf = web3.utils.fromWei(balanceOf);
      settokenbalance({ balance: balance, balanceOf: balanceOf });
      var token_power = parseFloat(config.tokenpower) * parseFloat(balanceOf)
      settokenpower(token_power)
      setstatus("yes")
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <ReactTooltip />
      <div className="inner_wrapper">
        <div className="inner_pageheader">
          <div className="container">
            <div className="primary_box dash_box make_proposal_panel mb-5">
              <div className="inner_banner main-banner">
                <div className="inner_banner_content">
                  <div className="inner_title_top">
                    <h1><span className="pink_light_txt">Voting</span> Details</h1>
                  </div>
                  <p className="w-100">Have your say in the future of the social community.</p>
                  <div className="voting_banner_btn">
                    <button className="primary_btn w_sm_100_btn ml-0">
                      <Link to="/makeproposal">Make a Proposal</Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="inner_content_wrapper">
            <div className="container">
              <div className="got_suggest">
                <h2 className="inner_title">{votedetail && votedetail.title}</h2>
                <p>{ReactHtmlParser(votedetail.explanation)}</p>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="primary_box dash_box make_proposal_panel mb-3 vote_poll_div">
                    <p>Cast Your Vote</p>
                    <div class="custom-radios">
                      {votedetail && votedetail.choice && (votedetail.choice).map((choice, i) => {
                        return (
                          <div>
                            <input onClick={() => mychoice(choice.option)} type="radio" id={"color-" + (parseInt(i) + parseInt(1))} name="color" value={"color-" + (parseInt(i) + parseInt(1))} />
                            <label for={"color-" + (parseInt(i) + parseInt(1))}>
                              <span>
                                <img src={require("../assets/images/tick_icon.png")} alt="Checked Icon" />
                              </span>
                              {choice.option}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                    {localStorage.getItem("account") && localStorage.getItem("account") != null && localStorage.getItem("account") != "" ?
                      <button className="primary_btn ml-0 w_sm_100_btn" disabled='true'>{(localStorage.getItem("account")).substring(0, 4) + '.....' + (localStorage.getItem("account")).substring((localStorage.getItem("account")).length - 4, (localStorage.getItem("account")).length)}</button>
                      :
                      <button className="primary_btn ml-0 w_sm_100_btn" onClick={() => connectwall()}>Connect Wallet</button>
                    }

                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="primary_box dash_box make_proposal_panel d_m_none mb-3">
                    <div class="whitebox-header">
                      <h2 class="whitebox-title">{votedetail && votedetail.votes ? "Votes (" + (votedetail.votes).length + ")" : "Votes (0)"}</h2>
                    </div>
                    <div className="votes_list_table">
                      <div className="table-responsive">
                        <table className="table order_book_table">
                          <thead>
                            <tr>
                              <th>Voter</th>
                              <th>Decision</th>
                              <th>Vote Weight</th>
                            </tr>
                          </thead>
                          <tbody>
                            {votedetail && votedetail.votes && votedetail.votes.length > 0 && (votedetail.votes).map((lists, j) => {
                              let val = (votedetail.votes).filter(x => x.choice == lists.choice).length
                              if (parseInt(j) + parseInt(1) < parseInt(page)) {
                                return (
                                  <tr>
                                    <td>{lists.address.substring(0, 4) + '.....' + lists.address.substring(lists.address.length - 4, lists.address.length)}</td>
                                    <td className="green_txt">{lists.choice}</td>
                                    <td>{val}</td>
                                  </tr>
                                )
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      {votedetail && votedetail.votes && parseInt(page) > parseInt(votedetail.votes).length ? <p className="pink_light_txt" onClick={() => loadmore()}>More<img src={require("../assets/images/dropdown_arrow_white.png")} alt="More Icon" className="ml-1" /></p> : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="primary_box dash_box make_proposal_panel whitebox_vote_details mb-3">
                    <div class="whitebox-header justify-content-center">
                      <h2 class="whitebox-title">Details</h2>
                    </div>
                    <div className="vote_details_list_top">
                      <div>
                        <label>Identifier</label>
                        {localStorage.getItem("account") && localStorage.getItem("account") != null && localStorage.getItem("account") != "" ?
                          <label>{(localStorage.getItem("account")).substring(0, 4) + '.....' + (localStorage.getItem("account")).substring((localStorage.getItem("account")).length - 4, (localStorage.getItem("account")).length)}</label>
                          :
                          <label>-----</label>
                        }

                      </div>
                      <div>
                        <label>Creator</label>
                        <label>{votedetail && (votedetail.creator).substring(0, 4) + '.....' + (votedetail.creator).substring(votedetail.creator.length - 4, votedetail.creator.length)}</label>
                      </div>
                      {/* <div>
                            <label>Snapshot</label>
                            <label>153245689</label>
                          </div> */}
                    </div>
                    <div className="vote_details_list_bottom">
                      <div>
                        <label>Start date</label>
                        <label>{votedetail && votedetail.startdate && moment(votedetail.startdate).format("DD-MM-YYYY HH:MM")}</label>
                      </div>
                      <div>
                        <label>End date</label>
                        <label>{votedetail && votedetail.enddate && moment(votedetail.enddate).format("DD-MM-YYYY HH:MM")}</label>
                      </div>
                    </div>
                    {votedetail.creator === walletaddress ?<button type="button" disabled='true' className="primary_btn mt-3 w-100 ml-0" >You are creator</button>:
                    startdiffer > 0 ?
                      <button type="button" className="primary_btn mt-3 w-100 ml-0" >Coming Soon</button>
                      : enddiffer > 0 ?
                        <button type="button" className="primary_btn mt-3 w-100 ml-0" onMouseOver={votepowercallevent} onClick={status == "yes" ? () => vote() : ""}>{status == "yes" ? "Vote Now" : localStorage.getItem("account") && localStorage.getItem("account") != "" ? "Calculating..Please Wait" : "Vote Now"}</button>
                        :
                        <button type="button" className="primary_btn mt-3 w-100 ml-0" >Closed</button>
                    }
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="primary_box dash_box make_proposal_panel mb-3 whitebox_vote_details">
                    <div class="whitebox-header justify-content-center">
                      <h2 class="whitebox-title">Current Results</h2>
                    </div>
                    {votedetail && votedetail.choice && (votedetail.choice).map((choice) => {
                      let length = (votedetail.votes).filter(x => x.choice == choice.option).length
                      return (
                        <div className="agree_result_div">
                          <h4 className="green_txt text-center">{choice && choice.option}</h4>
                          <div class="progress mb-2">
                            <div class={"progress-bar w-" + length / 100} role="progressbar" aria-valuenow={length / 100} aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="vote_count">{length} Votes</p>
                            <p className="green_txt">{length / 100} %</p>
                          </div>
                        </div>
                      )
                    })
                    }


                    {/* <div className="disagree_result_div">
                          <h4 className="red_txt text-center">No We Don't</h4>
                          <div class="progress mb-2">
                            <div class="progress-bar w-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="vote_count">16,209.56 Votes</p>
                            <p className="red_txt">24.73 %</p>
                          </div>
                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader_section_">
          <ReactLoading type={"bars"} color={"#ffffff"} className="loading" />
        </div>
      )}
      <Footer />
    </div>
  );
}
