import React, { useState } from "react";
import ReactLoading from 'react-loading';

const Loader = () => {

  return (
    <div className="loader_section_">
      <ReactLoading type={"bars"} color={"#ffffff"} className="loading" />
    </div>
  )
}

export default Loader;